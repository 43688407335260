import { userApi } from 'api'
import axios from 'axios'
import { TextButton, TextInput } from 'components'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { encodePass } from 'utils/common'
import styles from './login.module.scss'

export const Default = ({ setPageStatus, setDialogStatus }) => {
  const navigate = useNavigate()
  const usernameRef = useRef()
  const passwordRef = useRef()

  const login = async (e) => {
    e.preventDefault()
    try {
      const res = (
        await userApi.login({
          username: usernameRef.current.value,
          password: encodePass(passwordRef.current.value),
          fcm_token:
            'fwgxYrmnm0tvzqOtgo1sJ5:APA91bEFQZgRcNzWYk053VhNUjdmSp78slFFiHIbIdu2HykLtLeWrCKiUeDuwAcLN0SOJHGfFWpYczo8UodRtlpjooUzoHNZE_vPSm-PwBqlPMiRCWpKXRS00VCm4PMd1KoGIIrH2LQ2',
        })
      ).data
      axios.defaults.headers['access-token'] = res.access_token
      localStorage.setItem('access_token', res.access_token)
      localStorage.setItem('refresh_token', res.refresh_token)
      localStorage.setItem('email', res.email)
      localStorage.setItem('hospital', res.hospital)
      res.profile && localStorage.setItem('profile', res.profile)

      navigate('/today')
    } catch (err) {
      console.error(err)

      // 아이디가 없을 경우
      if (err.response.data.message === 'this username does not exist.')
        setDialogStatus('noId')
      else if (err.response.data.message === 'password is not matched.')
        setDialogStatus('pwErr')
      else setDialogStatus('loginErr')
    }
  }

  return (
    <>
      <p className={styles.handymedi_desc}>상처닥 의료진 페이지</p>
      <form onSubmit={login} method="POST" className="relative">
        <label htmlFor="username">아이디</label>
        <TextInput
          id="username"
          ref={usernameRef}
          placeholder="아이디를 입력해주세요"
        />
        <label htmlFor="password">비밀번호</label>
        <TextInput
          id="password"
          ref={passwordRef}
          placeholder="비밀번호를 입력해주세요"
          isPasswordType={true}
        />
        <div className={`${styles.find} blue pointer fw500 mt24`}>
          <span onClick={() => setPageStatus('findId')}>아이디 찾기</span>
          <span onClick={() => setPageStatus('findPw')}>비밀번호 찾기</span>
        </div>
        <TextButton
          onSubmit={true}
          className="btn_blue w154 h38 fs16 right"
          innerText="로그인"
        />
      </form>
    </>
  )
}
