import { api } from './core'

export const userApi = {
  login: (params) => api.post('/medi/session', params),
  logout: () => api.delete('/medi/session'),
  findId: (params) => api.post('/medi/find-id', params),
  findPw: (params) => api.post('/medi/find-pw', params),
  resetPw: (params) => api.put('/medi/reset-pw', params),
  hospitalInfo: () => api.get('/medi/info'),
  profileInfo: () => api.get('/medi'),
  profileUpdate: (form) => api.formPut('/medi', form),
  withdrawal: () => api.delete('/medi'),
  calculateLogin: (password) =>
    api.post('/medi/calculate/password', { password }),
  calculateDetail: (params) => api.get('/medi/calculate', params),
}
