// https://github.com/JedWatson/react-select

import { chevronGray } from 'assets/img'
import Select from 'react-select'

export const SelectBox = ({
  state,
  setState,
  options,
  placeholder,
  width,
  height,
}) => {
  const selectBoxStyles = {
    container: () => ({
      position: 'relative',
      zIndex: 9,
    }),
    control: (_, { selectProps: { width, height } }) => ({
      width: width,
      height: height,
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #dfe3eb',
      borderRadius: '2px',
      cursor: 'pointer',
      overflow: 'hidden',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: () => ({
      background: `url(${chevronGray}) center no-repeat`,
      width: '16px',
      height: '16px',
      position: 'relative',
      right: '10px',
    }),
    menu: (_, { selectProps: { width } }) => ({
      background: '#fff',
      position: 'absolute',
      width: width,
      border: '1px solid #dfe3eb',
      borderTop: 'none',
    }),
    menuList: (_) => ({
      ..._,
      '::-webkit-scrollbar': {
        width: '6px',
        opacity: '0',
        paddingLeft: '10px',
        left: '-10px',
        position: 'absolute',
        background: '#9B9FA7',
      },
      '::-webkit-scrollbar-thumb': {
        boxShadow: 'inset 0 0 4px 4px #386A98',
      },
      '::-webkit-scrollbar-button': {
        display: 'none',
      },
      '::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 14px 14px transparent',
        border: 'solid 4px transparent',
      },
    }),

    option: (_, { isFocused, isSelected }) => ({
      backgroundColor: isSelected || isFocused ? '#DFE3EB' : undefined,
      fontSize: '14px',
      padding: '7px 10px',
      transition: 'background 0.1s',
    }),
  }

  return (
    <Select
      maxMenuHeight={162}
      styles={selectBoxStyles}
      width={width}
      height={height}
      className="select-box"
      value={state}
      onChange={setState}
      options={options}
      placeholder={placeholder}
      isSearchable={false}
    />
  )
}
