import { useState, useEffect } from 'react'
import { qnaApi } from 'api'
export const ImgReadView = ({ file, onClick }) => {
  const [imgFile, setImgFile] = useState('')

  const getImgRead = async () => {
    try {
      const imgData = await qnaApi.photoRead(file)
      let img = URL.createObjectURL(imgData.data)
      setImgFile(img)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    return () => setImgFile('')
  }, [])
  useEffect(() => {
    setImgFile('')
    getImgRead()
  }, [file])

  return (
    <img
      src={imgFile && imgFile}
      alt=""
      onClick={() => {
        onClick && onClick()
      }}
    />
  )
}
