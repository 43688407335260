import { noticeApi } from 'api'
import { Loader } from 'components'
import { Template } from 'layouts/Template'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { getFormattedDate, useQueryCommon } from 'utils/common'
import styles from '../notice.module.scss'

export const NoticeDetail = () => {
  const { _id } = useParams()
  const [isImgUrl, setImgUrl] = useState('')

  const { data: noticeDetailReq } = useQuery(
    'noticeDetailQuery',
    async () => (await noticeApi.detail(_id)).data,
    {
      ...useQueryCommon,
      onSuccess: (data) => {
        data.image && convertImgFile(data.image)
      },
    }
  )

  const convertImgFile = async (imgUrl) => {
    const headers = { 'access-token': localStorage.getItem('access_token') }
    const requestOptions = {
      method: 'GET',
      headers: headers,
    }
    const url = imgUrl
    const response = await fetch(url, requestOptions)
    const data = await response.blob()
    const src = URL.createObjectURL(data)
    setImgUrl(src)
  }
  const _ = noticeDetailReq

  if (!_) return <Loader />

  return (
    <Template>
      <h2>공지사항</h2>

      <main className={styles.content_box}>
        <div className={styles.header}>
          <h3>
            {_.title}
            <span className="fs16">
              {getFormattedDate(new Date(_.createdAt))}
            </span>
          </h3>
        </div>
        <div className={styles.content}>
          {isImgUrl && (
            <img className={styles.img} src={isImgUrl} alt="notice_img" />
          )}
          {_.content}
        </div>
      </main>
    </Template>
  )
}
