import { noticeApi } from 'api'
import { Loader, Pagination } from 'components'
import { Template } from 'layouts/Template'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { getFormattedDay, useQueryCommon } from 'utils/common'

export const NoticeList = () => {
  const navigate = useNavigate()

  // paginate
  const [page, setPage] = useState(1)

  const { data: noticeReq } = useQuery(
    'noticeQuery',
    async () => (await noticeApi.list(page)).data,
    useQueryCommon
  )

  const data = [
    { key: 'num', name: '글번호' },
    { key: 'title', name: '제목' },
    { key: 'createdAt', name: '작성 일시' },
  ]

  const _ = noticeReq
  // const _ = []

  if (!_) return <Loader />

  return (
    <Template>
      <h2 className="mb24">공지사항</h2>
      <>
        <table className="qna notice">
          <thead>
            <tr>
              {data.map((th, index) => (
                <th key={index}>{th.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {_.results.length > 0 ? (
              _.results.map((result, resultIndex) => (
                <tr
                  key={resultIndex}
                  onClick={() => navigate(`/notice/${result._id}`)}
                >
                  {data.map((td, index) => (
                    <td key={index}>
                      {(() => {
                        switch (td.key) {
                          case 'num':
                            return (
                              _?.total_count - (page - 1) * 10 - resultIndex
                            )
                          case 'createdAt':
                            return getFormattedDay(new Date(result[td.key]))
                          default:
                            return result[td.key]
                        }
                      })()}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr className="hover_none">
                <td
                  style={{
                    width: '100%',
                  }}
                >
                  <p
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      color: '#a7a7a7',
                    }}
                  >
                    공지사항이 없습니다.
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          pageLimit={5}
          viewLimit={10}
          totalCount={_?.total_count || 0}
          page={page || 0}
          setPage={setPage}
          nextAble={_?.total_page > page || 0}
          prevAble={page !== 1}
        />
      </>
    </Template>
  )
}
