import { userApi } from 'api'
import { logo } from 'assets/img'
import { counselNewCount } from 'pages/Counsel/common'
import { qnaNewCount } from 'pages/Qna/common'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { GNB } from 'utils/dictionary'

export const Header = ({ clickState, clickEvent, setLink }) => {
  const { pathname } = useLocation()
  const [isQnaCnt, setQnaCnt] = useState(0)
  const [isCounselCnt, setCounselCnt] = useState(0)
  const email = localStorage.getItem('email')

  const logout = async () => {
    try {
      await userApi.logout()
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      window.location.href = '/login'
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    qnaNewCount(setQnaCnt)
    counselNewCount(setCounselCnt)
  }, [])

  return (
    <header id="header">
      <div>
        <h1>
          <Link
            to={clickState || '/today'}
            onClick={() => clickState && setLink('/today')}
          >
            <img src={logo} alt="scardoc" />
          </Link>
        </h1>
        <nav>
          {GNB.map((nav) => (
            <Link
              to={clickState || nav.to}
              onClick={() => clickState && setLink(nav.to)}
              key={nav.to}
              className={pathname.startsWith(nav.to) ? 'active' : ''}
            >
              {nav.to === '/newQna' && (
                <span className="qna_cnt">{isQnaCnt}</span>
              )}
              {nav.to === '/newCounsel' && (
                <span className="qna_cnt">{isCounselCnt}</span>
              )}
              {nav.name}
            </Link>
          ))}
        </nav>
        <details>
          <summary>
            <em>{email}</em>
          </summary>
          <ul>
            <li>
              <Link
                to={clickState || '/profile'}
                onClick={() => clickState && setLink('/profile')}
              >
                회원정보 수정
              </Link>
            </li>
            <li>
              <button onClick={logout}>로그아웃</button>
            </li>
          </ul>
        </details>
      </div>
    </header>
  )
}
