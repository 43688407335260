import { replay } from 'assets/img'
import {
  Dialog,
  Loader,
  Pagination,
  PaginationText,
  SearchContainer,
  SelectBox,
  Table,
  TextButton,
  TextInput,
} from 'components'
import { Template } from 'layouts/Template'
import {
  CSVdownload,
  getFormattedFullDate,
  linkOnClick,
  NumberComma,
  useStateCallback,
} from 'utils/common'
import { SEARCH_TYPE_CHARGE, SEARCH_TYPE_FILTER } from 'utils/dictionary'
import axios from 'axios'
import { ko } from 'date-fns/esm/locale'
import { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useQuery } from 'react-query'
import styles from '../calculate.module.scss'
import { userApi } from 'api'

export const CalculateDetail = () => {
  const startDateRef = useRef()
  const endDateRef = useRef()
  const [page, setPage] = useState(1) // paginate
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [name, setName] = useState('')
  const [isDateErrPopupOpened, setDateErrPopupOpened] = useStateCallback(false)
  const [isType, setType] = useState(SEARCH_TYPE_FILTER[0])
  const [isChargeStatus, setChargeStatus] = useState('')
  const [isReset, setReset] = useState(false)

  const { data: calculateList, refetch } = useQuery(
    'calculateListQuery',
    async () => {
      setReset(false)
      let params = {
        page,
        start_date: startDate ? startDate : new Date(),
        end_date: endDate ? endDate : new Date(),
      }
      if (name && name.trim().length > 0)
        params = { ...params, type: isType.value, query: name }
      if (isChargeStatus.value !== '')
        params = { ...params, charge_status: isChargeStatus.value }
      else params = { ...params }
      return (await userApi.calculateDetail(params)).data
    },
    {
      refetchOnWindowFocus: false,
      onError: (error) => console.log(error),
    }
  )

  const handleCSVdownload = async () => {
    try {
      let params = {
        start_date: startDate,
        end_date: endDate,
      }
      if (name && name.trim().length > 0)
        params = { ...params, type: isType.value, query: name }
      if (isChargeStatus !== '')
        params = { ...params, charge_status: isChargeStatus.value }
      CSVdownload('/medi/calculate/csv', params)
    } catch (error) {
      console.log(error)
    }
  }

  const calculateValues = (res) => [
    { key: '접수번호', width: 170, value: res?._id },
    { key: '환자명', width: 170, value: res?.user?.name },
    {
      key: '결제일',
      width: 180,
      value: res?.charge?.createdAt
        ? getFormattedFullDate(new Date(res?.charge?.createdAt))
        : '-',
    },
    {
      key: '결제비 (원)',
      width: 150,
      value: res?.charge?.cost ? NumberComma(res?.charge?.cost) : '0',
    },
    {
      key: 'PG수수료 (원)',
      width: 150,
      value: res?.pg_cost ? NumberComma(res?.pg_cost) : '0',
    },
    {
      key: '서비스 이용료 (원)',
      width: 150,
      value: res?.service_cost ? NumberComma(res?.service_cost) : '0',
    },
    {
      key: '정산비 (원)',
      width: 150,
      value: res?.calculate_cost ? NumberComma(res?.calculate_cost) : '0',
    },
    {
      key: '정산 상태',
      width: 140,
      value:
        res?.charge?.status === 'after_calculate' ? (
          <>
            <p>정산완료</p>
            <p>
              {res?.charge?.payment_date &&
                getFormattedFullDate(new Date(res?.charge?.payment_date))}
            </p>
          </>
        ) : (
          '정산 전'
        ),
    },
  ]

  useEffect(() => {
    !startDate && setStartDate(new Date())
    !endDate && setEndDate(new Date())
    localStorage.setItem('calculate', 'true')
  }, [])

  useEffect(() => {
    refetch()
  }, [page, isReset])

  const _ = calculateList

  // if (!_) return <Loader />

  return (
    <>
      <Template>
        <h2 className="mb30">정산내역</h2>
        <PaginationText
          results={_?.results?.length}
          totalCount={_?.total_count}
          page={_?.curr_page}
          setPage={setPage}
          nextAble={_?.next_able}
          prevAble={_?.prev_able}
        />
        <div className={styles.searchbox}>
          <SearchContainer
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            refetch={refetch}
            isSearchDate={true}
          />
          <form
            onSubmit={(e) => {
              e.preventDefault()
              refetch()
            }}
          >
            <SelectBox
              width="96px"
              height="32px"
              state={isType}
              setState={setType}
              options={SEARCH_TYPE_FILTER}
            />
            <TextInput
              value={name}
              width={221}
              height={32}
              onChange={(e) => setName(e.target.value)}
            />
            <SelectBox
              width="96px"
              height="32px"
              state={isChargeStatus}
              setState={setChargeStatus}
              options={SEARCH_TYPE_CHARGE}
              placeholder="정산상태"
            />
            <TextButton
              onSubmit={true}
              className="btn_bor_blue w106 h32"
              innerText="검색"
            />
            <img
              className={styles.reset_btn}
              src={replay}
              onClick={() => {
                setStartDate(new Date())
                setEndDate(new Date())
                setName('')
                setType(SEARCH_TYPE_FILTER[0])
                setChargeStatus('')
                setReset(true)
              }}
              alt=""
            />
          </form>
        </div>
        <Table
          className="calculate"
          resData={_?.results}
          values={calculateValues}
          emptyText="내역이 없습니다"
        />
        <div className={styles.total}>
          <h1>합계</h1>
          <div>
            <span>{_?.total_count}명</span>
            <span>{NumberComma(_?.total_cost)}</span>
            <span>{NumberComma(_?.total_pg)}</span>
            <span>{NumberComma(_?.total_service)}</span>
            <span className="fw600">{NumberComma(_?.total_calculate)}</span>
            <TextButton
              className="btn_blue w106 h26 fs14"
              innerText="상세 내역 저장"
              onClick={handleCSVdownload}
            />
          </div>
        </div>
      </Template>
      <Dialog
        className="fs18 fw500 h234 one-sentence"
        isOpened={isDateErrPopupOpened}
        setOpened={setDateErrPopupOpened}
        buttons={[
          { name: '확인', onClick: () => setDateErrPopupOpened(false) },
        ]}
      >
        날짜를 다시 확인해주세요
      </Dialog>
    </>
  )
}
