// 여러번 쓰이는 html
import { putFile } from 'utils/common'

export const GetTreatTimeBeforeTreat = ({ result }) => {
  const interval = new Date(result.treat_start).getTime() - new Date().getTime()

  const minute = Math.floor(Math.abs(interval) / 60000)

  return minute === 0 ? (
    '진료를 시작해주세요'
  ) : interval > 0 ? (
    <span className="before-treat-minute">{minute}분 뒤 진료 시작</span>
  ) : (
    <span className="red">진료 예정 {minute}분 초과</span>
  )
}

// 뷰어 -> 사용자 이미지 추가 preview 버튼
export const PreviewButton = ({
  viewerData,
  setViewerOpened,
  setStartIndex,
  setDialogStatus,
}) => {
  return (
    <>
      {viewerData.map((item, index) => (
        <li key={index} className={item.state ? '' : 'no-contents'}>
          <input
            type="file"
            accept="image/*"
            ref={item.ref}
            onChange={(e) => putFile(e, item.setState)}
          />
          <button
            className="thumnail"
            type="button"
            onClick={() => {
              if (item.state) {
                setViewerOpened(true)
                setStartIndex(index)
              } else {
                item.ref.current.click()
              }
            }}
          >
            {item.state && (
              <img
                draggable={false}
                src={
                  typeof item.state === 'string'
                    ? `${item.state}?token=${localStorage.getItem(
                        'access_token'
                      )}`
                    : window.URL.createObjectURL(item.state)
                }
              />
            )}
          </button>
          {item.state && (
            // 이미지 첨부 취소 버튼
            <button
              className="delete_img"
              onClick={() => {
                setDialogStatus({
                  type: 'checkDeleteImg',
                  item: item,
                })
              }}
            ></button>
          )}
        </li>
      ))}
    </>
  )
}
