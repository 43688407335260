import React, { useState } from 'react'
import { SelectBox } from './SelectBox'
import { TextButton } from './TextButton'
import { TextInput } from './TextInput'

export const SearchInputBox = ({
  setSearchType,
  query,
  setQuery,
  title,
  placeholder,
  selectBoxProps,
  filterButton,
}) => {
  return (
    <form
      className="searchbox name"
      onSubmit={(e) => {
        e.preventDefault()
        setSearchType && setSearchType('name')
      }}
    >
      {title && <em className={selectBoxProps ? 'mr20' : 'mr24'}>{title}</em>}
      <div className="flex gap24 grow1">
        {selectBoxProps && (
          <SelectBox
            width="110px"
            height="32px"
            state={selectBoxProps.state}
            setState={selectBoxProps.setState}
            options={selectBoxProps.options}
          />
        )}
        <TextInput
          value={query}
          placeholder={placeholder}
          width={340}
          height={32}
          onChange={(e) => setQuery(e.target.value)}
          className="mr8"
        />
      </div>
      <TextButton
        onSubmit={true}
        width={80}
        height={32}
        className="btn_bor_blue fs16 ml24"
        innerText="검색"
      />
    </form>
  )
}
