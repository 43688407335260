import {
  Checkbox,
  Dialog,
  Loader,
  TextArea,
  TextButton,
  Viewer,
} from 'components'
import { GetTreatTimeBeforeTreat, PreviewButton } from 'components/common'
import { Template } from 'layouts/Template'
import {
  convertFileFormat,
  download,
  getFormattedDate,
  getFormattedPhoneNum,
  NumberComma,
  putFile,
  sliceDate,
  useStateCallback,
} from 'utils/common'
import { VIDEO_FORMAT } from 'utils/dictionary'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { generateDialogUI } from '../common'
import styles from './recordDetail.module.scss'
import { recordApi } from 'api'
import { ImgReadView } from 'components/ImgReadView'

export const RecordDetail = () => {
  const { _id } = useParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [isLoaderActive, setLoaderActive] = useState(false)

  // 환자 첨부파일 이미지 뷰어 관련
  const [isPatientViewerOpened, setPatientViewerOpened] =
    useStateCallback(false)
  const [startIndexPatientViewer, setStartIndexPatientViewer] = useState(null)

  // 진료 / 접수 취소 관련
  const cancelReasonRef = useRef()
  const recommendInputRef = useRef() // 추천과
  const etcInputRef = useRef() // 기타 취소 사유

  // 처방전 전송 PDF 파일 첨부
  const prescriptionFileRef = useRef()
  const [prescriptionFile, setPrescriptionFile] = useState()
  const [isNoPrescription, setNoPrescription] = useState(false) // 처방전 없음 체크박스
  const [isReplacePrescription, setReplacePrescription] = useState(true) // 대체 처방 여부 체크박스

  // 진료비
  const [cost, setCost] = useState('')
  const [isNoCost, setNoCost] = useState(false) // 진료비 없음 체크박스

  // 사후 관리
  const [postManagementText, setPostManagementText] = useState('')

  // 사후 관리 이미지 뷰어 관련
  const [isManageViewerOpened, setManageViewerOpened] = useStateCallback(false)
  const [startIndexManageViewer, setStartIndexManageViewer] = useState(null)
  const imgInputRef0 = useRef()
  const imgInputRef1 = useRef()
  const imgInputRef2 = useRef()
  const imgInputRef3 = useRef()
  const imgInputRef4 = useRef()
  const [img0, setImg0] = useState()
  const [img1, setImg1] = useState()
  const [img2, setImg2] = useState()
  const [img3, setImg3] = useState()
  const [img4, setImg4] = useState()

  const manageViewerData = [
    {
      state: img0,
      setState: setImg0,
      ref: imgInputRef0,
    },
    {
      state: img1,
      setState: setImg1,
      ref: imgInputRef1,
    },
    {
      state: img2,
      setState: setImg2,
      ref: imgInputRef2,
    },
    {
      state: img3,
      setState: setImg3,
      ref: imgInputRef3,
    },
    {
      state: img4,
      setState: setImg4,
      ref: imgInputRef4,
    },
  ]

  const { data: detailInfo, refetch } = useQuery(
    'recordDetailInfo',
    async () => (await recordApi.detail(_id)).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setDialogStatus({ type: null, id: _id })

        if (data.type === 'after_treat') {
          // 처방전 유무 관련 조건문
          if (data.charge.prescription === null) {
            setNoPrescription(true)
          } else {
            setPrescriptionFile(data.charge.prescription)
          }

          // 대체 처방 여부 관련 조건문
          if (
            data.charge.replace_prescription === null ||
            data.charge.replace_prescription === false
          ) {
            setReplacePrescription(false)
          } else {
            setReplacePrescription(true)
          }

          // 진료비 청구 유무 관련 조건문
          if (!data.charge.cost) {
            setNoCost(true)
          } else {
            setCost(String(data.charge.cost))
          }
          setPostManagementText(data.post_management.text)

          data.post_management.images &&
            data.post_management.images.forEach((img, index) => {
              manageViewerData[index].setState(img)
            })
        }
      },
      onError: (error) => console.log(error),
    }
  )

  const _ = detailInfo

  // 팝업
  const [dialogStatus, setDialogStatus] = useState({ type: null, id: '' })
  // 팝업 종류

  const [isAcceptPopupOpened, setAcceptPopupOpened] = useStateCallback(false)
  const [isCheckAcceptPopupOpened, setCheckAcceptPopupOpened] =
    useStateCallback(false)
  const [isChangePopupOpened, setChangePopupOpened] = useStateCallback(false)
  const [isCheckChangePopupOpened, setCheckChangePopupOpened] =
    useStateCallback(false)
  const [isCancelPopupOpened, setCancelPopupOpened] = useStateCallback(false)
  const [isRecommendErrPopupOpened, setRecommendErrPopupOpened] =
    useStateCallback(false)
  const [isCancelReasonErrPopupOpened, setCancelReasonErrPopupOpened] =
    useStateCallback(false) // 취소 사유 미선택
  const [isEtcErrPopupOpened, setEtcErrPopupOpened] = useStateCallback(false) // 기타 입력하지 않음

  const [isCheckTreatReqPopupOpened, setCheckTreatReqPopupOpened] =
    useStateCallback(false)
  const [isCheckChangeTreatReqPopupOpened, setCheckChangeTreatReqPopupOpened] =
    useStateCallback(false)
  const [costErr, setCostErr] = useStateCallback(false)
  const [isPrescriptionErrPopupOpened, setPrescriptionErrPopupOpened] =
    useStateCallback(false)
  const [isCheckDelImgPopupOpened, setCheckDelImgPopupOpened] =
    useStateCallback(false) // 이미지 첨부 삭제 팝업
  const [isUpdateErrPopupOpened, setUpdateErrPopupOpened] =
    useStateCallback(false) // 환자 삭제 진료내역 -> 수정 불가
  const [updateErrText, setUpdateErrText] = useState('')

  // 접수 관리 API
  const [treatTime, setTreatTime] = useState()
  const [cancelReason, setCancelReason] = useState('')

  const dialogUI = {
    ...generateDialogUI({
      cancelReasonRef,
      recommendInputRef,
      etcInputRef,
      dialogStatus,
      setDialogStatus,
      isAcceptPopupOpened,
      setAcceptPopupOpened,
      isCheckAcceptPopupOpened,
      setCheckAcceptPopupOpened,
      isChangePopupOpened,
      setChangePopupOpened,
      isCheckChangePopupOpened,
      setCheckChangePopupOpened,
      isCancelPopupOpened,
      setCancelPopupOpened,
      isRecommendErrPopupOpened,
      setRecommendErrPopupOpened,
      isCancelReasonErrPopupOpened,
      setCancelReasonErrPopupOpened,
      isEtcErrPopupOpened,
      setEtcErrPopupOpened,
      treatTime,
      setTreatTime,
      cancelReason,
      setCancelReason,
      refetch,
    }),
    // 진료 완료 확인
    checkTreatReq: {
      setOpened: setCheckTreatReqPopupOpened,
      jsx: (
        <Dialog
          className="treat-check h234 fs18 fw500 text-center"
          isOpened={isCheckTreatReqPopupOpened}
          setOpened={setCheckTreatReqPopupOpened}
          buttons={[
            {
              name: '취소',
              onClick: () => setDialogStatus({ ...dialogStatus, type: null }),
            },
            {
              name: '완료',
              onClick: () => submitPrescription(_.type),
            },
          ]}
        >
          <div>
            <p
              style={
                isNoPrescription
                  ? { marginBottom: '28px' }
                  : { marginBottom: '14px' }
              }
            >
              진료를 완료하시겠습니까?
              <em className="fs14 fw400 block">
                {isNoPrescription ||
                  `*대체 처방이 ${
                    isReplacePrescription ? '' : '불'
                  }가능한 처방전입니다.`}
              </em>
            </p>
            진료비<em className="mr50">{NumberComma(cost)}원</em>
            처방전<em>{isNoPrescription ? '없음' : '첨부 완료'}</em>
          </div>
        </Dialog>
      ),
    },
    // 진료 및 처방 정보 수정 확인
    checkChangeTreatReq: {
      setOpened: setCheckChangeTreatReqPopupOpened,
      jsx: (
        <Dialog
          className="treat-check h234 fs18 fw500 text-center"
          isOpened={isCheckChangeTreatReqPopupOpened}
          setOpened={setCheckChangeTreatReqPopupOpened}
          buttons={[
            {
              name: '취소',
              onClick: () => setDialogStatus({ ...dialogStatus, type: null }),
            },
            {
              name: '완료',
              onClick: () => submitPrescription(_.type),
            },
          ]}
        >
          <div>
            <p
              style={
                isNoPrescription
                  ? { marginBottom: '28px' }
                  : { marginBottom: '14px' }
              }
            >
              진료 및 처방 정보를 수정하시겠습니까?
              <em className="fs14 fw400 block">
                {isNoPrescription ||
                  `*대체 처방이 ${
                    isReplacePrescription ? '' : '불'
                  }가능한 처방전입니다.`}
              </em>
            </p>
            진료비<em className="mr50">{NumberComma(cost)}원</em>
            처방전<em>{isNoPrescription ? '없음' : '첨부 완료'}</em>
          </div>
        </Dialog>
      ),
    },
    costErr: {
      setOpened: setCostErr,
      jsx: (
        <Dialog
          className="fs18 fw500 h234 one-sentence"
          isOpened={costErr}
          setOpened={setCostErr}
          buttons={[
            {
              name: '확인',
              onClick: () => setDialogStatus({ ...dialogStatus, type: null }),
            },
          ]}
        >
          진료비 청구 금액이 너무 작습니다. (최소 금액 : 100원)
        </Dialog>
      ),
    },
    prescriptionErr: {
      setOpened: setPrescriptionErrPopupOpened,
      jsx: (
        <Dialog
          className="fs18 fw500 h234 one-sentence"
          isOpened={isPrescriptionErrPopupOpened}
          setOpened={setPrescriptionErrPopupOpened}
          buttons={[
            {
              name: '확인',
              onClick: () => setDialogStatus({ ...dialogStatus, type: null }),
            },
          ]}
        >
          처방전을 확인해주세요
        </Dialog>
      ),
    },
    checkDeleteImg: {
      setOpened: setCheckDelImgPopupOpened,
      jsx: (
        <Dialog
          className="fs18 fw500 h234 one-sentence"
          isOpened={isCheckDelImgPopupOpened}
          setOpened={setCheckDelImgPopupOpened}
          buttons={[
            {
              name: '취소',
              onClick: () => {
                setDialogStatus({ ...dialogStatus, type: null })
              },
            },
            {
              name: '확인',
              onClick: () => {
                dialogStatus.item.setState(null)
                setDialogStatus({ ...dialogStatus, type: null })
              },
            },
          ]}
        >
          삭제하시겠습니까?
        </Dialog>
      ),
    },
    updateErr: {
      setOpened: setUpdateErrPopupOpened,
      jsx: (
        <Dialog
          className="fs18 fw500 h234 one-sentence"
          isOpened={isUpdateErrPopupOpened}
          setOpened={setUpdateErrPopupOpened}
          buttons={[
            {
              name: '확인',
              onClick: () => setDialogStatus({ ...dialogStatus, type: null }),
            },
          ]}
        >
          {updateErrText}
        </Dialog>
      ),
    },
  }

  const submitPrescription = async (type) => {
    setLoaderActive(true)

    try {
      const form = new FormData()
      form.append('_id', _id)
      form.append('cost', Number(cost))
      form.append('post_management', postManagementText)

      // 처방전 파일 유무에 따른 조건
      if (isNoPrescription) {
        form.append('no_prescription', true)
      } else {
        form.append('no_prescription', false)
        if (prescriptionFile.constructor.name === 'File')
          form.append('prescription', prescriptionFile)
      }

      // 대체 처방 여부에 따른 조건
      if (isReplacePrescription) {
        form.append('replace_prescription', true)
      } else {
        form.append('replace_prescription', false)
      }

      img0 && (await convertFileFormat(form, 'images', img0))
      img1 && (await convertFileFormat(form, 'images', img1))
      img2 && (await convertFileFormat(form, 'images', img2))
      img3 && (await convertFileFormat(form, 'images', img3))
      img4 && (await convertFileFormat(form, 'images', img4))

      // 진료 완료) 의료진 진료 처방 및 비용 청구 수정
      if (type === 'after_treat') {
        await recordApi.chargeUpdate(form)
        navigate('/history')
      }
      // 의료진 진료 처방 및 비용 청구
      else {
        await recordApi.chargeCreate(form)
        navigate('/today')
      }
      refetch()
    } catch (err) {
      console.error(err)
      const reason = err.response.data.reason
      if (reason && err.response.status === 403) {
        setDialogStatus({ ...dialogStatus, type: 'updateErr' })
        if (reason === 'INVALID_TYPE') setUpdateErrText('비정상적인 접근입니다')
        else if (reason === 'TIME_EXCEED')
          setUpdateErrText('수정할 수 있는 기간이 초과되었습니다')
        else if (reason === 'USER_HIDDEN')
          setUpdateErrText('환자가 삭제한 진료내역이므로 수정할 수 없습니다')
      } else if (err.response.status === 503) {
        alert(err.response.data.message)
      }
      setLoaderActive(false)
    }
  }

  const treatStatusObj = {
    before_accept: {
      type: '접수 대기',
      manage: () => {
        return (
          <>
            <TextButton
              className="btn_plus w94 h26 fs14"
              innerText="접수 승인"
              onClick={() =>
                dialogStatus &&
                setDialogStatus({ ...dialogStatus, type: 'accept' })
              }
            />
            <TextButton
              className="btn_delete w94 h26 fs14 ml8"
              innerText="접수 취소"
              onClick={() =>
                dialogStatus &&
                setDialogStatus({ ...dialogStatus, type: 'cancel' })
              }
            />
          </>
        )
      },
    },
    before_treat: {
      type: '진료 전',
      manage: () => (
        <>
          <TextButton
            className="btn_update w94 h26 fs14"
            innerText="대기시간 수정"
            onClick={() =>
              dialogStatus &&
              setDialogStatus({ ...dialogStatus, type: 'change' })
            }
          />
          <TextButton
            className="btn_delete w94 h26 fs14 ml8"
            innerText="진료 취소"
            onClick={() =>
              dialogStatus &&
              setDialogStatus({ ...dialogStatus, type: 'cancel' })
            }
          />
        </>
      ),
    },
    cancel_accept: {
      type: '접수 취소/진료 취소',
    },
    cancel_treat: { type: '접수 취소/진료 취소' },
    after_treat: { type: '진료 완료' },
  }

  useEffect(() => {
    dialogStatus.type && dialogUI[dialogStatus.type].setOpened(true)
  }, [dialogStatus])

  useEffect(() => {
    // 처방전 없음에 체크가 되면 -> 파일이 첨부되지 않음
    if (isNoPrescription) {
      setReplacePrescription(false)
      setPrescriptionFile(null)
      prescriptionFileRef.current.value = ''
    }
  }, [isNoPrescription])

  useEffect(() => {
    // 진료비 없음에 체크가 되면 -> 진료비 0원
    isNoCost && setCost('')
  }, [isNoCost])

  if (!_) return <Loader />

  return (
    <>
      {isLoaderActive && <Loader />}

      <Template>
        <Link
          to={`/${pathname.split('/')[1]}`}
          className="btn_bor_gray go-back fs16 fw500 mb21"
          style={{ width: '207px' }}
        >
          진료 내역으로 돌아가기
        </Link>
        <article>
          <h3>
            환자 정보
            <dl>
              <dt className="fs14 fw500 mr14">접수 일시</dt>
              <dd className="fw500 mr40">
                {getFormattedDate(new Date(_?.createdAt))}
              </dd>
              <dt className="fs14 fw500 mr14">진료번호</dt>
              <dd className="fw500">{_?._id}</dd>
            </dl>
          </h3>
          <div className={styles.patient_info}>
            <div className="flex">
              <dl className={styles.info}>
                <dt>진료 대상자 이름</dt>
                <dd>{_?.family?.name || _?.user.name}</dd>
                <dt>주민등록번호</dt>
                <dd>{_?.resident_number}</dd>
                <dt>대리접수 여부</dt>
                <dd>{_.family ? 'O' : 'X'}</dd>
              </dl>
              <dl className={styles.info}>
                <dt>연락처</dt>
                <dd>{getFormattedPhoneNum(_.family?.phone || _.user.phone)}</dd>
                <dt>성별</dt>
                <dd
                  className={classNames(
                    {
                      [styles.female]:
                        (_.family?.gender || _.user.gender) === '2' ||
                        (_.family?.gender || _.user.gender) === '4',
                    },
                    styles.gender
                  )}
                ></dd>
                <dt>접수자와의 관계</dt>
                <dd>
                  {(() => {
                    const relation = _.family?.relation
                    if (relation === 'parent') return '부모'
                    else if (relation === 'spouse') return '배우자'
                    else if (relation === 'child') return '자녀'
                    else return '본인'
                  })()}
                </dd>
              </dl>
              <dl className={styles.info}>
                <dt>상처 종류</dt>
                <dd>{_?.depart_symptom}</dd>
                {_?.depart_symptom === '화상' && (
                  <dl className={styles.info_question}>
                    <dl>
                      <dt>상처 사진</dt>
                      {_?.questionnaire?.photo1 ? (
                        <div className={styles.img_view}>
                          <ImgReadView file={_?.questionnaire?.photo1} />{' '}
                        </div>
                      ) : (
                        <dd>-</dd>
                      )}
                    </dl>
                    <dl>
                      <dl className="flex">
                        <dt>종류</dt>
                        <dd>{_?.questionnaire?.burn_cause || '-'}</dd>
                      </dl>
                      <dl className="flex">
                        <dt>수상일</dt>
                        <dd>
                          {(_?.questionnaire?.burn_date &&
                            sliceDate(_?.questionnaire?.burn_date)) ||
                            '-'}
                        </dd>
                      </dl>
                      <dl className="flex">
                        <dt>부위</dt>
                        <dd>{_?.questionnaire?.burn_area || '-'}</dd>
                      </dl>
                      <dl className="flex">
                        <dt>크기</dt>
                        <dd>{_?.questionnaire?.burn_size || '-'}</dd>
                      </dl>
                      <dl className="flex">
                        <dt>물집</dt>
                        <dd>{_?.questionnaire?.blister || '-'}</dd>
                      </dl>
                    </dl>
                  </dl>
                )}
              </dl>
            </div>
            <div className={`${styles.card} ${styles[_.type]}`}>
              <div className="flex align-center">
                <span>접수 상태</span>
                <div className={`text-center ${styles.treat_type}`}>
                  {/* 진료 상태 */}
                  <em>{treatStatusObj[_.type].type}</em>
                  {/* 진료 대기 시간 / 진료 정보 */}
                  <p className="fw700 gray-dark">
                    {
                      // 진료 전
                      _.type === 'before_treat' && (
                        <GetTreatTimeBeforeTreat result={_} />
                      )
                    }
                    {
                      // 진료 완료
                      _.type === 'after_treat' &&
                        getFormattedDate(new Date(_.updatedAt))
                    }
                  </p>
                </div>
              </div>
              <div className="flex align-center">
                {(_.type === 'before_accept' || _.type === 'before_treat') && (
                  <>
                    <span>접수 관리</span>
                    {treatStatusObj[_.type].manage(_._id)}
                  </>
                )}
              </div>

              {(_.type === 'cancel_accept' || _.type === 'cancel_treat') && (
                <>
                  <span className="red">취소 사유</span>
                  <div
                    className="block text-box mt8"
                    style={{ height: '88px', overflow: 'auto' }}
                  >
                    {_.cancel_reason}
                  </div>
                </>
              )}
            </div>
            <dl className={styles.symptom}>
              <dt>환자 증상</dt>
              <dd className="text-box">{_.symptom.text}</dd>
            </dl>
            <dl className={styles.files}>
              <dt>환자 첨부파일</dt>
              <dd>
                {_.symptom.images.length > 0 ? (
                  <ul className="viewer-preview">
                    {_.symptom.images.map((img, index) => (
                      <li key={index}>
                        <button
                          className="thumnail"
                          type="button"
                          onClick={() => {
                            setPatientViewerOpened(true)
                            setStartIndexPatientViewer(index)
                          }}
                        >
                          {VIDEO_FORMAT.includes(img.split('.').pop()) ? (
                            <video>
                              <source
                                src={`${img}?token=${localStorage.getItem(
                                  'access_token'
                                )}`}
                              />
                              죄송합니다, 현재 브라우저에서 영상을 첨부할 수
                              없습니다. 다른 브라우저를 이용해 주세요
                            </video>
                          ) : (
                            <img
                              src={`${img}?token=${localStorage.getItem(
                                'access_token'
                              )}`}
                            />
                          )}
                        </button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className={styles.no_file}>첨부 파일이 없습니다</div>
                )}
              </dd>
            </dl>
          </div>
        </article>

        {/* 진료 전, 진료 완료 시에만 활성 */}
        {(_.type === 'before_treat' || _.type === 'after_treat') && (
          <>
            <article className="mt50">
              <h3>처방전 전송 • 진료비 청구</h3>
              <div className={styles.prescription}>
                <div>
                  <label className={styles.title}>처방전 전송</label>
                  <div className="mb64 relative">
                    <div className="flex align-center">
                      <div
                        className={`text-box ${styles.prescription_file_name}`}
                      >
                        <button
                          title="다운로드"
                          onClick={() =>
                            typeof prescriptionFile === 'string'
                              ? download(
                                  `${prescriptionFile}?token=${localStorage.getItem(
                                    'access_token'
                                  )}`,
                                  `처방전_${_._id}.pdf`
                                )
                              : download(
                                  window.URL.createObjectURL(prescriptionFile),
                                  prescriptionFile.name.split('/').pop()
                                )
                          }
                        >
                          {prescriptionFile
                            ? typeof prescriptionFile === 'string'
                              ? prescriptionFile && `처방전_${_._id}.pdf`
                              : prescriptionFile.name.split('/').pop()
                            : ''}
                        </button>
                      </div>
                      <input
                        type="file"
                        accept=".pdf"
                        ref={prescriptionFileRef}
                        onChange={(e) => putFile(e, setPrescriptionFile)}
                      />
                      <TextButton
                        innerText="파일 선택"
                        className="btn_plus w94 h26 ml12 mr10"
                        onClick={() =>
                          !isNoPrescription &&
                          prescriptionFileRef.current.click()
                        }
                      />
                    </div>
                    <div className="flex gap10 mt10">
                      <Checkbox
                        id="no-prescription"
                        label="처방전 없음"
                        isChecked={isNoPrescription}
                        onChange={() => {
                          setNoPrescription(!isNoPrescription)
                        }}
                      />
                      <Checkbox
                        id="replace-prescription"
                        label="대체 처방"
                        defaultCursor={isNoPrescription}
                        isChecked={isReplacePrescription}
                        onChange={() => {
                          !isNoPrescription &&
                            setReplacePrescription(!isReplacePrescription)
                        }}
                      />
                    </div>
                    {!prescriptionFile && !isNoPrescription && (
                      <span className={styles.alert}>
                        *처방전을 확인해주세요
                      </span>
                    )}
                  </div>
                  <div className="relative">
                    <label className={styles.title}>진료비 청구</label>
                    <div className="flex align-center">
                      <NumericFormat
                        className={styles.cost}
                        value={Number(cost)}
                        thousandSeparator={true}
                        isAllowed={() => (isNoCost ? false : true)}
                        onValueChange={(values) =>
                          setCost(String(values.floatValue))
                        }
                      />
                      <span className={styles.unit}>원</span>
                      <Checkbox
                        id="no-cost"
                        label="진료비 없음"
                        isChecked={isNoCost}
                        onChange={() => {
                          setNoCost(!isNoCost)
                        }}
                      />
                    </div>

                    {((!isNoCost && isNaN(cost)) || (!isNoCost && !cost)) && (
                      <span className={styles.alert}>
                        *진료비를 확인해주세요
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  <label className={styles.title}>사후 관리</label>
                  <TextArea
                    height={88}
                    className="mb40"
                    value={postManagementText}
                    onChange={(e) => setPostManagementText(e.target.value)}
                  />
                  <label className={styles.title}>사후 관리 자료 첨부</label>
                  <div className={`relative`}>
                    <ul className={`viewer-preview`}>
                      <PreviewButton
                        viewerData={manageViewerData}
                        setViewerOpened={setManageViewerOpened}
                        setStartIndex={setStartIndexManageViewer}
                        setDialogStatus={setDialogStatus}
                      />
                    </ul>
                    <span className={styles.short_desc}>
                      * 이미지 파일 형식, 최대 5개 업로드 가능
                    </span>
                  </div>
                </div>
              </div>
            </article>
            <TextButton
              onSubmit={true}
              className="btn_blue w196 h38 right mt24 fs18"
              innerText={`${
                _.type === 'after_treat' ? '수정 후 ' : ''
              } 전송하기`}
              onClick={() => {
                // 처방전 없음 체크 없이 처방전을 첨부하지 않았을 때 에러 팝업
                if (!prescriptionFile && !isNoPrescription)
                  setDialogStatus({
                    ...dialogStatus,
                    type: 'prescriptionErr',
                  })
                // 청구 금액이 100원 보다 작을 시 에러 팝업
                else if (!isNoCost && cost < 100)
                  setDialogStatus({
                    ...dialogStatus,
                    type: 'costErr',
                  })
                else {
                  // 진료 및 처방 정보 수정
                  if (_.type === 'after_treat')
                    setDialogStatus({
                      ...dialogStatus,
                      type: 'checkChangeTreatReq',
                    })
                  // 진료 완료
                  else
                    setDialogStatus({
                      ...dialogStatus,
                      type: 'checkTreatReq',
                    })
                }
              }}
            />
          </>
        )}
      </Template>
      <Viewer
        onClick={() => setStartIndexPatientViewer(null)}
        isOpened={isPatientViewerOpened}
        setOpened={setPatientViewerOpened}
        title="환자 첨부파일"
        items={_.symptom.images.map((img) => {
          const data = {
            original: `${img}?token=${localStorage.getItem('access_token')}`,
            thumbnail: `${img}?token=${localStorage.getItem('access_token')}`,
          }

          return VIDEO_FORMAT.includes(img.split('.').pop())
            ? {
                ...data,
                video: true,
              }
            : data
        })}
        startIndex={startIndexPatientViewer}
      />
      <Viewer
        onClick={() => setStartIndexManageViewer(null)}
        isOpened={isManageViewerOpened}
        setOpened={setManageViewerOpened}
        title="사후 관리 자료"
        items={manageViewerData.map((data) => {
          let thumbnail

          if (data.state) {
            thumbnail =
              typeof data.state === 'string'
                ? `${data.state}?token=${localStorage.getItem('access_token')}`
                : window.URL.createObjectURL(data.state)
          }

          return {
            thumbnail,
            original: thumbnail,
          }
        })}
        startIndex={startIndexManageViewer}
      />
      {dialogStatus.type && dialogUI[dialogStatus.type].jsx}
    </>
  )
}
