import { Link } from 'react-router-dom'

export const TextButton = ({
  onSubmit,
  className,
  innerText,
  onClick,
  linkTo,
  width,
  height,
  fontSize,
}) => (
  <button
    style={{
      width: width && `${width}px`,
      height: height && `${height}px`,
      fontSize: fontSize && `${fontSize}px`,
    }}
    type={onSubmit ? 'submit' : 'button'}
    className={className}
    onClick={(e) => {
      e.stopPropagation()
      onClick && onClick()
    }}
  >
    {linkTo ? <Link to={linkTo}>{innerText}</Link> : innerText}
  </button>
)
