import React from 'react'
import * as ReactDOM from 'react-dom/client'
import { RecoilRoot } from 'recoil'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ScardocMediRoutes } from './routes'

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <ScardocMediRoutes />
    </QueryClientProvider>
  </RecoilRoot>
)
