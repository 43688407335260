export const SERVER_URL = '/api'

// Header - Global Navigation
export const GNB = [
  { name: '당일 진료 내역', to: '/today' },
  { name: '지난 진료 내역', to: '/history' },
  { name: '새로운 질문', to: '/newQna' },
  { name: '완료된 질문', to: '/finishQna' },
  { name: '새로운 상담', to: '/newCounsel' },
  { name: '완료된 상담', to: '/finishCounsel' },
  { name: '공지사항', to: '/notice' },
  // { name: '고객센터', to: '/inquiry' },
  { name: '정산내역', to: '/calculate' },
]

// 고객센터
export const INQUIRY_CATEGORIES = [
  { value: '서비스 이용문의', label: '서비스 이용문의' },
  { value: '정산', label: '정산' },
  { value: '사이트 오류', label: '사이트 오류' },
  { value: '개선제안', label: '개선제안' },
  { value: '기타', label: '기타' },
]

// 접수 승인 및 대기시간
export const TREAT_TIMES = [5, 10, 15, 20, 30, 40, 50, 60]

// 접수 / 진료 취소 사유
export const CANCEL_REASONS = [
  '병원 현장 이슈 (방문 환자 급증 등)',
  '응급 환자 대응',
  '응급 수술 (분만)',
  '진료 분야 아님',
  '기타',
]

// video format
export const VIDEO_FORMAT = [
  'mp4',
  'mov',
  'wmv',
  'avi',
  'avchd',
  'flv',
  'f4v',
  'swf',
  'mkv',
]

export const SYMPTOM_TAGS = [
  { value: 'symptom_all', label: '전체 선택' },
  { value: '화상', label: '화상' },
  { value: '아토피', label: '아토피' },
  { value: '상처', label: '상처' },
  { value: '흉터', label: '흉터' },
  { value: '욕창', label: '욕창' },
  { value: '탈모', label: '탈모' },
]

// 검색
export const SEARCH_TYPE_FILTER = [
  { value: 'medi', label: '의사명' },
  { value: 'user', label: '환자명' },
]

export const SEARCH_TYPE_CHARGE = [
  { value: '', label: '전체보기' },
  { value: 'before_calculate', label: '정산 전' },
  { value: 'after_calculate', label: '정산완료' },
]
