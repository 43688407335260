import { Dialog } from 'components'
import { useStateCallback } from 'utils/common'
import { useEffect, useState } from 'react'
import { Default } from './Default'
import { FindId } from './FindId'
import { FindPw } from './FindPw'
import styles from './login.module.scss'
import { NewPw } from './NewPw'

export const Login = () => {
  const [pageStatus, setPageStatus] = useState('default')

  const [dialogStatus, setDialogStatus] = useState('')
  // Default Page
  const [isPwErrPopupOpened, setPwErrPopupOpened] = useStateCallback(false)
  const [isLoginErrPopupOpened, setLoginErrPopupOpened] =
    useStateCallback(false)

  // FindID Page
  const [finedID, setFinedID] = useState('')
  const [isIdInfoPopupOpened, setIdInfoPopupOpened] = useStateCallback(false)
  const [isNoIdPopupOpened, setNoIdPopupOpened] = useStateCallback(false)

  // FindPw Page
  const [isNotMatchPopupOpened, setNotMatchPopupOpened] =
    useStateCallback(false)

  // New Pw
  const [isNewPwDonePopupOpened, setNewPwDonePopupOpened] =
    useStateCallback(false)
  const [mediId, setMediId] = useState('')
  const [token, setToken] = useState('')

  const pageUI = {
    default: {
      title: '상처닥',
      jsx: (
        <Default
          setPageStatus={setPageStatus}
          setDialogStatus={setDialogStatus}
        />
      ),
    },
    findId: {
      findArrIndex: 0,
      title: '회원정보 찾기',
      jsx: (
        <FindId
          setPageStatus={setPageStatus}
          setDialogStatus={setDialogStatus}
          setFinedID={setFinedID}
        />
      ),
    },
    findPw: {
      findArrIndex: 1,
      title: '회원정보 찾기',
      jsx: (
        <FindPw
          setMediId={setMediId}
          setToken={setToken}
          setPageStatus={setPageStatus}
          setDialogStatus={setDialogStatus}
        />
      ),
    },
    newPw: {
      findArrIndex: 1,
      title: '회원정보 찾기',
      jsx: (
        <NewPw
          mediId={mediId}
          token={token}
          setDialogStatus={setDialogStatus}
        />
      ),
    },
  }

  const findArr = [
    { name: '아이디 찾기', setState: () => setPageStatus('findId') },
    { name: '비밀번호 찾기', setState: () => setPageStatus('findPw') },
  ]

  const loginDialogUI = {
    // Default Page
    pwErr: {
      setOpened: setPwErrPopupOpened,
      jsx: (
        <Dialog
          className="fs18 fw500 h234 one-sentence"
          isOpened={isPwErrPopupOpened}
          setOpened={setPwErrPopupOpened}
          buttons={[{ name: '확인', onClick: () => setDialogStatus('') }]}
        >
          비밀번호를 확인해주세요
        </Dialog>
      ),
    },

    loginErr: {
      setOpened: setLoginErrPopupOpened,
      jsx: (
        <Dialog
          className="one-sentence"
          isOpened={isLoginErrPopupOpened}
          setOpened={setLoginErrPopupOpened}
          buttons={[{ name: '확인', onClick: () => setDialogStatus('') }]}
        >
          로그인 정보를 확인 해주세요
        </Dialog>
      ),
    },

    // FindID Page
    idInfo: {
      setOpened: setIdInfoPopupOpened,
      jsx: (
        <Dialog
          className="one-sentence user-select"
          isOpened={isIdInfoPopupOpened}
          setOpened={setIdInfoPopupOpened}
          buttons={[
            {
              name: '확인',
              onClick: () => {
                setDialogStatus('')
                setPageStatus('default')
              },
            },
          ]}
        >
          입력하신 정보의 ID는 {finedID} 입니다
        </Dialog>
      ),
    },

    noId: {
      setOpened: setNoIdPopupOpened,
      jsx: (
        <Dialog
          className="one-sentence"
          isOpened={isNoIdPopupOpened}
          setOpened={setNoIdPopupOpened}
          buttons={[
            {
              name: '확인',
              onClick: () => {
                setDialogStatus('')
                setPageStatus('default')
              },
            },
          ]}
        >
          존재하지 않는 ID 입니다
        </Dialog>
      ),
    },

    // FindPw Page
    notMatch: {
      setOpened: setNotMatchPopupOpened,
      jsx: (
        <Dialog
          className="one-sentence"
          isOpened={isNotMatchPopupOpened}
          setOpened={setNotMatchPopupOpened}
          buttons={[
            {
              name: '확인',
              onClick: () => {
                setDialogStatus('')
                setPageStatus('default')
              },
            },
          ]}
        >
          입력하신 정보가 일치하지 않습니다
        </Dialog>
      ),
    },

    // NewPw Page
    newPwDone: {
      setOpened: setNewPwDonePopupOpened,
      jsx: (
        <Dialog
          className="one-sentence"
          isOpened={isNewPwDonePopupOpened}
          setOpened={setNewPwDonePopupOpened}
          buttons={[
            {
              name: '확인',
              onClick: () => {
                setDialogStatus('')
                setPageStatus('default')
              },
            },
          ]}
        >
          비밀번호 재설정이 완료되었습니다
        </Dialog>
      ),
    },
  }

  useEffect(() => {
    dialogStatus && loginDialogUI[dialogStatus].setOpened(true)
  }, [dialogStatus])

  return (
    <>
      <section className={styles.login}>
        <h1 className={`fs24 fw500 ${pageStatus !== 'default' && 'mt_18'}`}>
          {pageUI[pageStatus].title}
        </h1>
        {pageStatus !== 'default' && (
          <nav className={styles.find_nav}>
            {findArr.map((item, index) => (
              <button
                key={index}
                className={
                  index === pageUI[pageStatus].findArrIndex ? styles.active : ''
                }
                onClick={item.setState}
              >
                {item.name}
              </button>
            ))}
          </nav>
        )}
        {pageUI[pageStatus].jsx}
      </section>
      {dialogStatus && loginDialogUI[dialogStatus].jsx}
    </>
  )
}
