import { scardocApi } from './core'

export const qnaApi = {
  newList: (params) => scardocApi.get('/qna/new_question_list', params),
  newCount: () => scardocApi.get('/qna/new_count'),
  detail: (id) => scardocApi.get(`/qna/question_detail?qid=${id}`),
  finishList: (params) => scardocApi.get('/qna/complete_question_list', params),
  saveAnswer: (params) => scardocApi.post(`/qna/answer`, params),
  photoRead: (file) => scardocApi.formGet(`/qna/photo/${file}`),
}
