import { counselApi, qnaApi } from 'api'
import { Loader, Pagination, Table } from 'components'
import { Template } from 'layouts/Template'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import {
  getFormattedDayDot,
  getFormattedDday,
  formatAgeGender,
  sliceDate,
  useQueryCommon,
} from 'utils/common'
import { counselNewCount } from '../common'

export const CounselList = () => {
  const { pathname } = useLocation()
  const [isNewCnt, setNewCnt] = useState(0)

  // paginate
  const [page, setPage] = useState(1)

  const { data: newCounselReq, refetch } = useQuery(
    'newCounselQuery',
    async () => {
      const { data } = await counselApi.list(
        page,
        pathname === '/newCounsel' ? 'new' : 'old'
      )
      return data
    },
    useQueryCommon
  )

  const newCounselValues = (res) => [
    {
      key: '상담요청일',
      width: 200,
      value: res?.createdAt && getFormattedDayDot(res?.createdAt),
    },
    {
      key: '작성자',
      width: 250,
      value: `${res?.user?.name} ${
        res?.user?.birth && formatAgeGender(res?.user?.birth, res?.user?.gender)
      }`,
    },
    {
      key: '질문 제목',
      width: 450,
      value: res?.title,
    },
    {
      key: '상처 종류',
      width: 230,
      value: res?.category,
    },
    {
      key: '답변작성일',
      width: 170,
      value: res?.answer_date ? getFormattedDayDot(res?.answer_date) : '-',
    },
  ]

  const _ = newCounselReq

  useEffect(() => {
    counselNewCount(setNewCnt)
  }, [])

  useEffect(() => {
    refetch()
  }, [page, pathname])

  if (!_) return <Loader />

  return (
    <Template
      title={
        pathname === '/newCounsel' ? (
          <>
            새로운 상담 <span className="qna_cnt">{isNewCnt}</span>
          </>
        ) : (
          '완료된 상담'
        )
      }
    >
      <Table
        resData={_?.results}
        values={newCounselValues}
        emptyText="내역이 없습니다"
        detailPageId="_id"
      />
      <Pagination
        pageLimit={5}
        viewLimit={_?.limit || 0}
        totalCount={_?.total_count || 0}
        page={page || 0}
        setPage={setPage}
        nextAble={_?.next_able}
        prevAble={_?.prev_able}
      />
    </Template>
  )
}
