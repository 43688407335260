import {
  getFormattedDate,
  getFormattedPhoneNum,
  NumberComma,
} from 'utils/common'
import { TextButton } from 'components'
import { useLocation, useNavigate } from 'react-router-dom'
import { GetTreatTimeBeforeTreat } from 'components/common'

export const RecordTable = ({ data, dialogStatus, setDialogStatus }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const thArr = [
    '진료 상태',
    '이름',
    '연락처',
    '진료비',
    '진료 대기 시간/진료 정보',
    '접수 관리',
  ]

  const treatStatusObj = {
    before_accept: {
      type: '접수 대기',
      cost: '청구 전',
      treat_start: '접수 전',
      manage: (id) => {
        return (
          <>
            <TextButton
              className="btn_plus w116 h34 fs14"
              innerText="접수 승인"
              onClick={() =>
                dialogStatus && setDialogStatus({ type: 'accept', id: id })
              }
            />
            <TextButton
              className="btn_delete w116 h34 fs14 ml8"
              innerText="접수 취소"
              onClick={() =>
                dialogStatus && setDialogStatus({ type: 'cancel', id: id })
              }
            />
          </>
        )
      },
    },
    before_treat: {
      type: '진료 전',
      cost: '청구 전',
      manage: (id) => (
        <>
          <TextButton
            className="btn_update w116 h34 fs14"
            innerText="대기시간 수정"
            onClick={() =>
              dialogStatus && setDialogStatus({ type: 'change', id: id })
            }
          />
          <TextButton
            className="btn_delete w116 h34 fs14 ml8"
            innerText="진료 취소"
            onClick={() =>
              dialogStatus && setDialogStatus({ type: 'cancel', id: id })
            }
          />
        </>
      ),
    },
    cancel_accept: {
      type: '접수 취소',
      cost: '-',
    },
    cancel_treat: { type: '진료 취소', cost: '-' },
    after_treat: { type: '진료 완료' },
  }

  return (
    <table className="records">
      <thead>
        <tr>
          {thArr.map((th, index) => (
            <th key={index}>{th}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.results.length ? (
          data.results.map((_, index) => (
            <tr
              key={index}
              className={`pointer ${_.type}`}
              onClick={() => navigate(`${pathname}/${_._id}`)}
            >
              {/* 진료 상태 */}
              <td>
                <em className="fw700">{treatStatusObj[_.type].type}</em>
                <span className="small">
                  {getFormattedDate(new Date(_.createdAt))}
                </span>
              </td>
              {/* 이름 */}
              <td className="fw500">{_.family?.name || _.user.name}</td>
              {/* 연락처 */}
              <td className="fw500">
                {getFormattedPhoneNum(_.family?.phone || _.user.phone)}
              </td>
              {/* 진료비 */}
              <td className="fw700">
                {_.type === 'after_treat' ? (
                  <>
                    {NumberComma(_.charge?.cost)}원
                    <span className="small">결제완료</span>
                  </>
                ) : (
                  treatStatusObj[_.type].cost
                )}
              </td>
              {/* 진료 대기 시간/진료 정보 */}
              <td className="fw700" title={_.cancel_reason && _.cancel_reason}>
                {(() => {
                  if (_.type === 'before_accept') {
                    return treatStatusObj[_.type].treat_start
                  } else if (_.type === 'before_treat') {
                    return <GetTreatTimeBeforeTreat result={_} />
                  } else if (
                    _.type === 'cancel_accept' ||
                    _.type === 'cancel_treat'
                  ) {
                    return `취소 사유 : ${_.cancel_reason}`
                  } else {
                    return getFormattedDate(new Date(_.treat_start))
                  }
                })()}
              </td>
              {/* 접수 관리 */}
              <td>
                {_.type === 'before_accept' || _.type === 'before_treat'
                  ? treatStatusObj[_.type].manage(_._id)
                  : '-'}
              </td>
            </tr>
          ))
        ) : (
          <tr className="empty fw700">
            <td>진료 내역이 없습니다</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
