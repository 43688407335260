import { qnaApi } from 'api'
import { Loader, Pagination, Table } from 'components'
import { Template } from 'layouts/Template'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import {
  getFormattedDayDot,
  getFormattedDday,
  sliceDate,
  useQueryCommon,
} from 'utils/common'
import { qnaNewCount } from '../common'

export const QnaNewList = () => {
  const [isNewCnt, setNewCnt] = useState(0)

  // paginate
  const [page, setPage] = useState(1)

  const { data: newQnAReq, refetch } = useQuery(
    'newQnAQuery',
    async () => {
      const params = {
        page,
        page_size: 10,
        get_all: false,
      }
      const { data } = await qnaApi.newList(params)
      return data.response
    },
    useQueryCommon
  )

  const newQnAValues = (res) => [
    {
      key: '상담요청일',
      width: 250,
      value: res && (
        <>
          <span>{getFormattedDayDot(res?.askedAt)}</span>
          <span>
            {' '}
            ({res?.asked_after === 0 ? '당일' : `${res?.asked_after}일 전`})
          </span>
        </>
      ),
    },
    {
      key: '환자 정보 ',
      width: 250,
      value: `${res?.name} (${res?.age}세, ${res?.gender})`,
    },
    {
      key: '수상일 (경과일)',
      width: 250,
      value: res && (
        <>
          <span>{sliceDate(res?.burn_date)?.replace(/\//gi, '.')}</span>
          <span>
            {' '}
            (
            {getFormattedDday(sliceDate(res?.burn_date))
              .replace('차', ' ')
              .trim()}
            )
          </span>
        </>
      ),
    },
    {
      key: '화상원인',
      width: 200,
      value: res?.burn_cause,
    },
    { key: '부위', width: 200, value: res?.burn_area },
    {
      key: '총 질문수',
      width: 120,
      value: res?.count,
    },
  ]

  const _ = newQnAReq

  useEffect(() => {
    qnaNewCount(setNewCnt)
  }, [])

  useEffect(() => {
    refetch()
  }, [page])

  if (!_) return <Loader />

  return (
    <Template
      title={
        <>
          새로운 질문 <span className="qna_cnt">{isNewCnt}</span>
        </>
      }
    >
      <Table
        resData={_?.list}
        values={newQnAValues}
        emptyText="내역이 없습니다"
        detailPageId="qid"
      />
      <Pagination
        pageLimit={5}
        viewLimit={10}
        totalCount={_?.list.length || 0}
        page={page || 0}
        setPage={setPage}
        nextAble={_?.total_page > page || 0}
        prevAble={page !== 1}
      />
    </Template>
  )
}
