import { Checkbox } from 'components'
import { multiCheckboxChangeAll } from 'utils/common'

export const FilterTable = ({ values }) => {
  return (
    <table className="filter">
      <tbody>
        {values().map((res, resIndex) => (
          <tr key={resIndex}>
            <td>{res.key}</td>
            <td>
              <ul>
                {res.value.map((tag, index) => (
                  <li key={index}>
                    <Checkbox
                      filter
                      isChecked={res.arr.includes(tag.value)}
                      id={tag.value}
                      label={tag.label}
                      onChange={() => {
                        multiCheckboxChangeAll(
                          tag.value,
                          res.arr,
                          res.setArr,
                          res.checkAll
                        )
                      }}
                    />
                  </li>
                ))}
              </ul>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
