// https://github.com/xiaolin/react-image-gallery

import { useEffect, useState } from 'react'
import ImageGallery from 'react-image-gallery'
import { download } from 'utils/common'

export const Viewer = ({
  isOpened,
  setOpened,
  title,
  items,
  onClick,
  startIndex,
}) => {
  const [currImg, setCurrImg] = useState()

  const bodyElem = document.querySelector('body')
  isOpened
    ? bodyElem?.classList.add('dialog')
    : bodyElem?.classList.remove('dialog')

  const mediGalleryRenderer = (item) => (
    <div className="image-gallery-image">
      {item.video ? (
        <video controls>
          <source src={item.original} />
          죄송합니다, 현재 브라우저에서 영상을 첨부할 수 없습니다. 다른
          브라우저를 이용해 주세요
        </video>
      ) : (
        <img src={item.original} />
      )}
    </div>
  )

  const mediThumbRenderer = (item) => (
    <span className="image-gallery-thumbnail-inner">
      {item.video ? (
        <video>
          <source src={item.original} />
          죄송합니다, 현재 브라우저에서 영상을 첨부할 수 없습니다. 다른
          브라우저를 이용해 주세요
        </video>
      ) : (
        <img src={item.original} />
      )}
    </span>
  )

  useEffect(() => {
    setCurrImg(
      document.querySelector(
        `.image-gallery-slides :nth-child(${startIndex + 1}) img`
      ) ||
        document.querySelector(
          `.image-gallery-slides :nth-child(${startIndex + 1}) source`
        )
    )
  }, [startIndex])

  return (
    <>
      {isOpened && (
        <dialog id="viewer">
          <header>
            <h3 className="fs18 dialog-title">{title}</h3>
            <button
              type="button"
              className="close"
              onClick={() => setOpened(false, () => onClick && onClick())}
            ></button>
          </header>
          <main className="relative">
            <ImageGallery
              items={items.filter((item) => item.original)}
              showFullscreenButton={false}
              showPlayButton={false}
              additionalClass="handimedi-gallery"
              slideInterval={100000}
              onSlide={(index) =>
                setCurrImg(
                  document.querySelector(
                    `.image-gallery-slides :nth-child(${index + 1}) img`
                  ) ||
                    document.querySelector(
                      `.image-gallery-slides :nth-child(${index + 1}) source`
                    )
                )
              }
              renderItem={mediGalleryRenderer.bind(items)}
              renderThumbInner={mediThumbRenderer.bind(items)}
              startIndex={startIndex}
            />
            <button
              type="button"
              className="save-image"
              onClick={() => {
                const url = currImg.getAttribute('src')
                download(url, url.split('/').pop())
              }}
            ></button>
          </main>
        </dialog>
      )}
    </>
  )
}
