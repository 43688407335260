import { Checkbox, Dialog, TextInput } from 'components'
import { getFormattedTime } from 'utils/common'
import { CANCEL_REASONS, TREAT_TIMES } from 'utils/dictionary'
import { Fragment } from 'react'
import { recordApi } from 'api'

export const generateDialogUI = ({
  cancelReasonRef,
  recommendInputRef,
  etcInputRef,
  dialogStatus,
  setDialogStatus,
  isAcceptPopupOpened,
  setAcceptPopupOpened,
  isCheckAcceptPopupOpened,
  setCheckAcceptPopupOpened,
  isChangePopupOpened,
  setChangePopupOpened,
  isCheckChangePopupOpened,
  setCheckChangePopupOpened,
  isCancelPopupOpened,
  setCancelPopupOpened,
  isRecommendErrPopupOpened,
  setRecommendErrPopupOpened,
  isCancelReasonErrPopupOpened,
  setCancelReasonErrPopupOpened,
  isEtcErrPopupOpened,
  setEtcErrPopupOpened,
  treatTime,
  setTreatTime,
  cancelReason,
  setCancelReason,
  refetch,
}) => {
  const receiptAPI = async (type) => {
    try {
      // 접수 취소
      if (type === 'reject') {
        await recordApi.receiptReject({
          _id: dialogStatus.id,
          type: type,
          cancel_reason: cancelReason,
        })
      }
      // 접수 승인 / 대기시간 수정
      else {
        await recordApi.receiptUpdate({
          _id: dialogStatus.id,
          type: type,
          minutes: Number(treatTime),
        })
      }
      setDialogStatus({ type: null, id: '' })
      refetch()
    } catch (err) {
      console.error(err)
    }
  }

  // 접수 승인 및 대기시간 수정 버튼 -> 첫번째로 활성화 되는 팝업 내용
  const selectTreatTime = () => (
    <div className="contents-wrapper">
      {TREAT_TIMES.map((minute, index) => (
        <Fragment key={index}>
          <input
            id={`${minute}분`}
            type="radio"
            name="treat_time"
            value={minute}
            onChange={(e) => setTreatTime(e.target.value)}
          />
          <label htmlFor={`${minute}분`}>{minute}분</label>
        </Fragment>
      ))}
    </div>
  )

  // 접수 승인 및 대기시간 수정 버튼 -> 두번째로 활성화 되는 팝업 내용
  const check = () => (
    <div className="text-center fs18 mt30">
      <p>선택하신 예상 진료시간은</p>
      <p className="mt10">
        <em className="fw700">
          {getFormattedTime(new Date(new Date().getTime() + 60000 * treatTime))}
        </em>
        입니다.
      </p>
    </div>
  )

  const cancelResonInputOnChange = (e, reason) => {
    // input에 내용이 있으면 user 입력 내용을 setState 시켜주고
    // input에 내용이 없으면 '기타'라고 setState 시켜줌
    const inputVal = e.target.value
    if (inputVal.trim().length >= 0)
      if (reason === '기타' && inputVal) setCancelReason(inputVal)
      else if (reason === '진료 분야 아님' && inputVal)
        setCancelReason(reason.concat(', 추천과 - ', inputVal))
      else setCancelReason(reason)
  }

  return {
    accept: {
      setOpened: setAcceptPopupOpened,
      jsx: (
        <Dialog
          className="select-treat-time"
          isOpened={isAcceptPopupOpened}
          setOpened={setAcceptPopupOpened}
          title="예상 진료 시간"
          buttons={[
            {
              name: '취소',
              onClick: () => setDialogStatus({ type: null, id: '' }),
            },
            {
              name: '확인',
              onClick: () =>
                treatTime &&
                setDialogStatus({ ...dialogStatus, type: 'checkAccept' }),
            },
          ]}
        >
          {selectTreatTime()}
        </Dialog>
      ),
    },
    checkAccept: {
      setOpened: setCheckAcceptPopupOpened,
      jsx: (
        <Dialog
          isOpened={isCheckAcceptPopupOpened}
          setOpened={setCheckAcceptPopupOpened}
          height={223}
          buttons={[
            {
              name: '취소',
              onClick: () => setDialogStatus({ type: null, id: '' }),
            },
            {
              name: '확인',
              onClick: () => {
                receiptAPI('accept')
                setTreatTime()
              },
            },
          ]}
        >
          {check()}
        </Dialog>
      ),
    },
    change: {
      setOpened: setChangePopupOpened,
      jsx: (
        <Dialog
          className="select-treat-time"
          isOpened={isChangePopupOpened}
          setOpened={setChangePopupOpened}
          title="대기시간 수정"
          buttons={[
            {
              name: '취소',
              onClick: () => setDialogStatus({ type: null, id: '' }),
            },
            {
              name: '확인',
              onClick: () =>
                treatTime &&
                setDialogStatus({ ...dialogStatus, type: 'checkChange' }),
            },
          ]}
        >
          {selectTreatTime()}
        </Dialog>
      ),
    },
    checkChange: {
      setOpened: setCheckChangePopupOpened,
      jsx: (
        <Dialog
          isOpened={isCheckChangePopupOpened}
          setOpened={setCheckChangePopupOpened}
          buttons={[
            {
              name: '취소',
              onClick: () => setDialogStatus({ type: null, id: '' }),
            },
            {
              name: '확인',
              onClick: () => {
                receiptAPI('change')
                setTreatTime()
              },
            },
          ]}
        >
          {check()}
        </Dialog>
      ),
    },
    cancel: {
      setOpened: setCancelPopupOpened,
      jsx: (
        <Dialog
          className="cancel-reason"
          isOpened={isCancelPopupOpened}
          setOpened={setCancelPopupOpened}
          title="취소 사유 선택"
          buttons={[
            { name: '취소', onClick: () => {} },
            {
              name: '확인',
              onClick: () => {
                if (!cancelReason.trim().length) {
                  // 취소 사유를 선택하지 않았을 때
                  setDialogStatus({ ...dialogStatus, type: 'cancelReasonErr' })
                } else if (cancelReason === '진료 분야 아님') {
                  // 진료 분야 아님 선택 -> 추천과를 작성하지 않았을 때
                  setDialogStatus({ ...dialogStatus, type: 'recommendErr' })
                } else if (cancelReason === '기타') {
                  // 기타 - 취소 사유를 작성하지 않았을 때
                  setDialogStatus({ ...dialogStatus, type: 'etcErr' })
                } else receiptAPI('reject')
              },
            },
          ]}
        >
          <div className="contents-wrapper" ref={cancelReasonRef}>
            {CANCEL_REASONS.map((reason, index) => (
              <div key={index}>
                <Checkbox
                  isRadio={true}
                  id={reason.replace(/(\s*)/g, '')}
                  value={reason}
                  name="cancel_reason"
                  label={reason}
                  onChange={(e) => {
                    const recommendVal = recommendInputRef.current.value
                    const etcVal = etcInputRef.current.value
                    // 추천과 또는 기타를 선택했을 경우,
                    // input에 내용이 있으면 user 입력 내용을 setState 시켜주고
                    // input에 내용이 없으면 reason 뒤에 user의 입력 값을 setState 시켜줌
                    if (reason === '기타' && etcVal) setCancelReason(etcVal)
                    else if (reason === '진료 분야 아님' && recommendVal)
                      setCancelReason(
                        reason.concat(', 추천과 - ', recommendVal)
                      )
                    else setCancelReason(e.target.value)
                  }}
                />

                {reason === '진료 분야 아님' && (
                  <TextInput
                    width={330}
                    height={30}
                    placeholder="추천과를 적어주세요!"
                    ref={recommendInputRef}
                    onFocus={() => {
                      cancelReasonRef.current
                        .querySelector(
                          `#${reason.replace(/(\s*)/g, '')} + label`
                        )
                        .click()
                    }}
                    onChange={(e) => cancelResonInputOnChange(e, reason)}
                  />
                )}

                {reason === '기타' && (
                  <TextInput
                    width={330}
                    height={30}
                    placeholder="급한 생리적 용무, 예측 불가 상황 등"
                    ref={etcInputRef}
                    onFocus={() => {
                      cancelReasonRef.current
                        .querySelector(`#${reason} + label`)
                        .click()
                    }}
                    onChange={(e) => cancelResonInputOnChange(e, reason)}
                  />
                )}
              </div>
            ))}
          </div>
        </Dialog>
      ),
    },
    recommendErr: {
      setOpened: setRecommendErrPopupOpened,
      jsx: (
        <Dialog
          className="fs18 fw500 h234 one-sentence"
          isOpened={isRecommendErrPopupOpened}
          setOpened={setRecommendErrPopupOpened}
          buttons={[
            {
              name: '확인',
              onClick: () => {
                setDialogStatus({ ...dialogStatus, type: 'cancel' })
              },
            },
          ]}
        >
          추천 진료과를 적어주세요!
        </Dialog>
      ),
    },
    cancelReasonErr: {
      setOpened: setCancelReasonErrPopupOpened,
      jsx: (
        <Dialog
          className="fs18 fw500 h234 one-sentence"
          isOpened={isCancelReasonErrPopupOpened}
          setOpened={setCancelReasonErrPopupOpened}
          buttons={[
            {
              name: '확인',
              onClick: () => {
                setDialogStatus({ ...dialogStatus, type: 'cancel' })
              },
            },
          ]}
        >
          취소 사유를 선택해주세요!
        </Dialog>
      ),
    },
    etcErr: {
      setOpeed: setEtcErrPopupOpened,
      jsx: (
        <Dialog
          className="fs18 fw500 h234 one-sentence"
          isOpened={isEtcErrPopupOpened}
          setOpened={setEtcErrPopupOpened}
          buttons={[
            {
              name: '확인',
              onClick: () => {
                setDialogStatus({ ...dialogStatus, type: 'cancel' })
              },
            },
          ]}
        >
          기타 사유를 입력해주세요!
        </Dialog>
      ),
    },
  }
}
