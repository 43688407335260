import { forwardRef } from 'react'

export const TextArea = forwardRef(
  (
    {
      id,
      placeholder,
      width,
      height,
      backgroundColor,
      className,
      readOnly,
      value,
      onChange,
      maxLength,
      defaultValue,
    },
    ref
  ) => (
    <textarea
      spellCheck="false"
      id={id}
      ref={ref}
      readOnly={readOnly}
      value={value}
      className={`textarea ${className ? className : ''}`}
      placeholder={placeholder}
      maxLength={maxLength}
      defaultValue={defaultValue}
      onChange={(e) => onChange && onChange(e)}
      style={{
        width: width && `${width}px`,
        height: height && `${height}px`,
        backgroundColor: backgroundColor && backgroundColor,
      }}
    ></textarea>
  )
)
