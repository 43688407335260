import { useNavigate } from 'react-router-dom'

export const Table = ({ resData, values, emptyText, detailPageId }) => {
  const navigate = useNavigate()

  return (
    <table className="qna">
      <thead>
        <tr>
          {values().map((field, _) => (
            <th
              key={_}
              className={`${field.shrink0 ? 'shrink0' : ''}`}
              style={{ width: `${field.width}px` }}
            >
              {field.key}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {resData?.length ? (
          resData.map((res, resIndex) => (
            <tr
              key={resIndex}
              onClick={() =>
                detailPageId &&
                navigate(`${detailPageId === 'qid' ? res?.qid : res?._id}`)
              }
              className={`${detailPageId ? 'pointer' : ''}`}
            >
              {values(res).map((_, fieldIndex) => (
                <td
                  key={fieldIndex}
                  className={`${_.shrink0 ? 'shrink0' : ''}`}
                  style={{ width: `${_.width}px` }}
                >
                  {_.value || '-'}
                </td>
              ))}
            </tr>
          ))
        ) : (
          <tr className="empty fw500">
            <td>{emptyText}</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
