export const PaginationText = ({
  results,
  totalCount,
  page,
  setPage,
  nextAble,
  prevAble,
  className,
}) => (
  <div className={`pagination-text ${className}`}>
    <span>
      {totalCount}개 중 {(page - 1) * 20 + 1}-{(page - 1) * 20 + results}
    </span>
    <button
      className={`prev-page ${!prevAble ? 'disable' : ''}`}
      onClick={() => prevAble && setPage(page - 1)}
    />
    <button
      className={`next-page ${!nextAble ? 'disable' : ''}`}
      onClick={() => nextAble && setPage(page + 1)}
    />
  </div>
)
