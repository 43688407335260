import { userApi } from 'api'
import { TextButton, TextInput } from 'components'
import { useRef } from 'react'
import styles from './login.module.scss'

export const FindPw = ({
  setMediId,
  setToken,
  setPageStatus,
  setDialogStatus,
}) => {
  const idRef = useRef()
  const nameRef = useRef()
  const phoneRef = useRef()

  const find = async (e) => {
    e.preventDefault()
    try {
      const res = (
        await userApi.findPw({
          username: idRef.current.value,
          name: nameRef.current.value,
          phone: phoneRef.current.value,
        })
      ).data
      setMediId(res.medi_id)
      setToken(res.token)
      setPageStatus('newPw')
    } catch (err) {
      console.error(err)
      setDialogStatus('notMatch')
    }
  }

  return (
    <>
      <form className={styles.find_form} onSubmit={find}>
        <label htmlFor="id">아이디</label>
        <TextInput id="id" ref={idRef} placeholder="아이디를 입력해주세요" />
        <label htmlFor="name">이름</label>
        <TextInput id="name" ref={nameRef} placeholder="이름을 입력해주세요" />
        <label htmlFor="phone">전화번호</label>
        <TextInput
          id="phone"
          ref={phoneRef}
          placeholder="전화번호를 입력해주세요"
        />
        <div className={styles.button_wrapper}>
          <TextButton
            innerText="뒤로"
            onClick={() => setPageStatus('default')}
            className="btn_back w80 h38 fs16 white"
          />
          <TextButton
            onSubmit={true}
            className="btn_blue w154 h38 fs16 right"
            innerText="비밀번호 재설정"
          />
        </div>
      </form>
    </>
  )
}
