import { Dialog, TextButton, TextInput } from 'components'
import { Template } from 'layouts/Template'
import { useStateCallback } from 'utils/common'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from '../calculate.module.scss'
import { userApi } from 'api'

export const CalculateLogin = () => {
  const navigate = useNavigate()
  const passwordRef = useRef()
  const [isErrPopupOpened, setErrPopupOpened] = useStateCallback(false)

  const login = async () => {
    if (!passwordRef.current.value.trim().length) setErrPopupOpened(true)
    else {
      try {
        await userApi.calculateLogin(passwordRef.current.value)
        localStorage.setItem('calculate', 'true')
        navigate('/calculate/detail')
      } catch (err) {
        passwordRef.current.value = ''
        setErrPopupOpened(true)
        console.error(err)
      }
    }
  }

  return (
    <>
      <Template>
        <section className={styles.login}>
          <div>
            <h1 className="mb51">비밀번호 입력</h1>

            <TextInput
              id="password"
              ref={passwordRef}
              width={345}
              height={38}
              isPasswordType={true}
              onKeyDown={(e) => e.keyCode === 13 && login()}
            />
            <span>오류, 문의 사항은 02-557-4958 로 연락주세요.</span>
          </div>
          <TextButton
            onClick={() => login()}
            className="btn_blue w226 h38 fs18 mt16 ml318"
            innerText="확인"
          />
        </section>
      </Template>
      <Dialog
        className="fs18 fw500 h234 one-sentence"
        isOpened={isErrPopupOpened}
        setOpened={setErrPopupOpened}
        buttons={[
          {
            name: '확인',
            onClick: () => {},
          },
        ]}
      >
        비밀번호를 확인하세요.
      </Dialog>
    </>
  )
}
