import axios from 'axios'

export const instance = axios.create({
  baseURL: '/dxMedi',
})

const scardocInstance = axios.create({
  baseURL: '/scardocMedi',
})

instance.interceptors.request.use(
  (config) => {
    const access_token = localStorage.getItem('access_token')
    if (access_token) {
      config.headers['access-token'] = access_token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

scardocInstance.interceptors.request.use(
  (config) => {
    const access_token = localStorage.getItem('access_token')
    if (access_token) {
      config.headers['Authorization'] = 'Bearer ' + access_token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export const api = {
  get: (url, params) =>
    instance({
      method: 'get',
      url,
      params,
    }),
  post: (url, data) =>
    instance({
      method: 'post',
      url,
      data,
    }),
  put: (url, data) =>
    instance({
      method: 'put',
      url,
      data,
    }),
  delete: (url, data) =>
    instance({
      method: 'delete',
      url,
      data,
    }),
  formPost: (url, form) =>
    instance.post(url, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  formPut: (url, form) =>
    instance.put(url, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  formGet: (url) =>
    instance.get(url, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
    }),
}

export const scardocApi = {
  get: (url, params) =>
    scardocInstance({
      method: 'get',
      url,
      params,
    }),
  post: (url, data) =>
    scardocInstance({
      method: 'post',
      url,
      data,
    }),
  put: (url, data) =>
    scardocInstance({
      method: 'put',
      url,
      data,
    }),
  delete: (url, data) =>
    scardocInstance({
      method: 'delete',
      url,
      data,
    }),
  formPost: (url, form) =>
    scardocInstance.post(url, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  formPut: (url, form) =>
    scardocInstance.put(url, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  formGet: (url) =>
    scardocInstance.get(url, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
    }),
}

const expiredAlert = () => {
  alert('세션이 만료되었습니다. 다시 로그인 해주세요')
  window.location.href = '/login'
}

const refreshAccessToken = async (refresh_token) => {
  try {
    const res = await api.post('/oauth/refresh', { refresh_token })
    instance.defaults.headers['access-token'] = res.data.access_token
    localStorage.setItem('access_token', res.data.access_token)
    window.location.reload()
  } catch (error) {
    expiredAlert()
  }
}

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 419) {
      if (error.response.data.message === 'EXPIRED_TOKEN') {
        const refresh_token = localStorage.getItem('refresh_token')
        if (refresh_token) {
          refreshAccessToken(refresh_token)
          return Promise.reject(error)
        }
      } else if (error.response.data.message === 'NO_TOKEN') {
        window.location.href = '/login'
        return Promise.reject(error)
      }
      expiredAlert()
    }
    return Promise.reject(error)
  }
)

scardocInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      if (error.response.data.message === 'EXPIRED_TOKEN') {
        const refresh_token = localStorage.getItem('refresh_token')
        if (refresh_token) {
          refreshAccessToken(refresh_token)
          return Promise.reject(error)
        }
      } else if (error.response.data.message === '토큰이 유효하지 않습니다') {
        const refresh_token = localStorage.getItem('refresh_token')
        if (refresh_token) {
          refreshAccessToken(refresh_token)
          return Promise.reject(error)
        }
      } else if (error.response.data.message === 'NO_TOKEN') {
        window.location.href = '/login'
        return Promise.reject(error)
      }
    } else if (error.response && error.response.status === 500) {
      // const refresh_token = localStorage.getItem('refresh_token')
      // if (refresh_token) {
      //   refreshAccessToken(refresh_token)
      //   return Promise.reject(error)
      // } else {
      //   expiredAlert()
      // }
    }
    return Promise.reject(error)
  }
)
