import { counselApi } from 'api'
import { defaultBurnImage, del } from 'assets/img'
import { Dialog, Loader, TextArea, TextButton } from 'components'
import { Template } from 'layouts/Template'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  formatAgeGender,
  getFormattedFullDate,
  useQueryCommon,
  useStateCallback,
} from 'utils/common'
import styles from './counselDetail.module.scss'

export const CounselDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const location = useLocation()
  const [isHistory, setHistory] = useState(window.history)

  const [isUpdate, setUpdate] = useState(false)
  const [isAnswer, setAnswer] = useState('')
  const [isLink, setLink] = useState('')

  const [dialogStatus, setDialogStatus] = useState({})
  const [isSuccessPopupOpend, setSuccessPopupOpened] = useStateCallback(false)
  const [isNoSavePopupOpened, setNoSavePopupOpened] = useStateCallback(false)
  const [isImgViewPopupOpened, setImgViewPopupOpened] = useStateCallback(false)

  const { data: _, refetch } = useQuery(
    'newQnaDetailQuery',
    async () => {
      const { data } = await counselApi.detail(id)
      data?.answer && setAnswer(data?.answer)
      return data
    },
    useQueryCommon
  )

  const handleCheckSave = (fn) => {
    if (isAnswer.length > 0 && _?.answer !== isAnswer)
      setDialogStatus({ type: 'noSave', fn: fn })
    else {
      if (fn === 'back') isUpdate ? navigate('/finishQna') : navigate('/newQna')
      else if (fn === 'link') navigate(isLink)
    }
  }

  const returnDialogFunction = (fn) => {
    setAnswer('')
    if (fn === 'back')
      isUpdate ? navigate('/finishCounsel') : navigate('/newCounsel')
    else if (fn === 'link') navigate(isLink)
  }

  const postCounselAnswer = async (answer) => {
    try {
      const req = {
        _id: _?._id,
        answer: isAnswer,
      }
      await counselApi.saveAnswer(req)
      setDialogStatus({ type: 'success', state: answer?.length > 0 })
    } catch (err) {
      console.error(err)
    }
  }

  const dialogUI = {
    success: {
      setOpened: setSuccessPopupOpened,
      jsx: (
        <Dialog
          className="one-sentence"
          isOpened={isSuccessPopupOpend}
          setOpened={setSuccessPopupOpened}
          buttons={[
            {
              name: '확인',
              onClick: () =>
                isUpdate ? navigate('/finishCounsel') : navigate('/newCounsel'),
            },
          ]}
        >
          {dialogStatus.state
            ? '답변이 수정되었습니다.'
            : '답변이 등록되었습니다.'}
        </Dialog>
      ),
    },
    noSave: {
      setOpened: setNoSavePopupOpened,
      jsx: (
        <Dialog
          className="h234 two-sentence"
          isOpened={isNoSavePopupOpened}
          setOpened={setNoSavePopupOpened}
          buttons={[
            {
              name: '돌아가기',
              onClick: () => {
                setLink('')
                setDialogStatus({})
              },
            },
            {
              name: '이동하기',
              onClick: () => returnDialogFunction(dialogStatus.fn),
            },
          ]}
        >
          답변 작성 중 화면 이탈 시<br />
          작성 중인 답변 내용은 저장되지 않습니다.
        </Dialog>
      ),
    },
    imgView: {
      setOpened: setImgViewPopupOpened,
      jsx: (
        <Dialog
          className="fs18 fw500 h781 w870"
          isOpened={isImgViewPopupOpened}
          setOpened={setImgViewPopupOpened}
        >
          <div className={styles.preview_img_del}>
            <img
              src={del}
              onClick={() => setImgViewPopupOpened(false)}
              alt=""
            />
          </div>
          <div className={styles.preview_img}>
            {dialogStatus?.img && <img src={dialogStatus?.img} alt="" />}
          </div>
        </Dialog>
      ),
    },
  }

  const preventClose = (e: BeforeUnloadEvent) => {
    e.preventDefault()
    e.returnValue = '' //Chrome에서 동작하도록; deprecated
  }

  const preventGoBack = () => {
    isHistory.pushState(null, '', location.href)
    handleCheckSave('back')
  }

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    setUpdate(location.pathname.startsWith('/finish'))
  }, [location])

  useEffect(() => {
    dialogStatus.type && dialogUI[dialogStatus.type].setOpened(true)
  }, [dialogStatus])

  useEffect(() => {
    ;(() => {
      window.addEventListener('beforeunload', preventClose)
    })()

    return () => {
      window.removeEventListener('beforeunload', preventClose)
    }
  }, [])
  useEffect(() => {
    ;(() => {
      isHistory.pushState(null, '', location.href)
      window.addEventListener('popstate', preventGoBack)
    })()

    return () => {
      window.removeEventListener('popstate', preventGoBack)
    }
  }, [isAnswer])

  useEffect(() => {
    isLink.length > 0 && handleCheckSave('link')
  }, [isLink])

  if (!_) return <Loader />

  return (
    <Template
      title={isUpdate ? '답변 수정하기' : '답변 등록하기'}
      link={isUpdate ? '/finishCounsel' : '/newCounsel'}
      clickState={isAnswer.length > 0 && _?.answer !== isAnswer}
      clickEvent={() => handleCheckSave('back')}
      setLink={setLink}
    >
      <div className={styles.talk}>
        <section className={styles.img_list}>
          <span>상처 이미지</span>
          <div className={styles.img_list__view}>
            {_?.images?.length > 0 ? (
              _?.images?.map((burns) => {
                return (
                  <div
                    className={styles.img_view}
                    key={burns}
                    onClick={() =>
                      setDialogStatus({ type: 'imgView', img: burns })
                    }
                  >
                    {burns && <img src={burns} alt="" />}
                  </div>
                )
              })
            ) : (
              <img src={defaultBurnImage} alt="" />
            )}
          </div>
        </section>
        <div>
          <section className={styles.contents}>
            <span>환자 정보</span>
            <dl className={styles.contents__info}>
              {_?.user?.name} {formatAgeGender(_?.user?.birth, _?.user?.gender)}
            </dl>
            <span>상담 내용</span>
            <div>
              <div className={styles.contents__box}>
                <dl>
                  <dt>상처 종류</dt>
                  <dd>
                    <div className={styles.contents__question}>
                      {_?.category}
                    </div>
                  </dd>
                </dl>
                <dl>
                  <dt>제목</dt>
                  <dd>
                    <div className={styles.contents__question}>{_?.title}</div>
                  </dd>
                </dl>
                <dl>
                  <dt>질문</dt>
                  <dd>
                    <div className={styles.contents__sub}>
                      <span>상담작성일시</span>
                      <span>
                        {_?.createdAt && getFormattedFullDate(_?.createdAt)}
                      </span>
                    </div>
                    <div className={styles.contents__question}>
                      {_?.content}
                    </div>
                  </dd>
                </dl>
                <dl>
                  <dt>답변</dt>
                  {_?.answer === null ? (
                    <dd>
                      <div className={styles.contents__sub}>
                        <TextArea
                          width={805}
                          height={117}
                          placeholder="내용을 입력해주세요."
                          value={isAnswer}
                          onChange={(e) => setAnswer(e.target.value)}
                        />
                      </div>
                    </dd>
                  ) : (
                    <dd>
                      <div className={styles.contents__sub}>
                        <span>상담작성일시</span>
                        <span>
                          {_?.answer_date &&
                            getFormattedFullDate(_?.answer_date)}
                        </span>
                      </div>
                      <TextArea
                        width={805}
                        height={101}
                        value={isAnswer}
                        onChange={(e) => setAnswer(e.target.value)}
                      />
                    </dd>
                  )}
                </dl>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="flex end mt24">
        {isAnswer.length > 0 && isAnswer !== _?.answer ? (
          <TextButton
            className="btn_blue fs16"
            innerText={_?.answer?.length > 0 ? '수정' : '등록'}
            width={184}
            height={40}
            onClick={() => postCounselAnswer(_?.answer)}
          />
        ) : (
          <TextButton
            className="btn_cancel hover_none fs16"
            innerText={_?.answer?.length > 0 ? '수정' : '등록'}
            width={184}
            height={40}
          />
        )}
      </div>

      {dialogStatus.type && dialogUI[dialogStatus.type].jsx}
    </Template>
  )
}
