import classNames from 'classnames'

export const Dialog = ({
  className,
  isOpened,
  setOpened,
  title,
  children,
  buttons,
  height,
}) => {
  // isOpened, setOpened 사용 방법은 아래와 같음
  // const [isDialogOpened, setDialogOpened] = useStateCallback(false)

  const bodyElem = document.querySelector('body')
  isOpened
    ? bodyElem?.classList.add('dialog')
    : bodyElem?.classList.remove('dialog')

  return (
    <>
      {isOpened && (
        <dialog
          id="dialog"
          className={className}
          style={{ height: height && `${height}px` }}
        >
          {title && <h3 className="dialog-title">{title}</h3>}
          {children}
          <menu>
            {buttons &&
              buttons.map((button, index) => (
                <button
                  key={index}
                  className={`${
                    // 버튼이 하나 있는 경우 - 확인 (blue)
                    // 버튼이 두개 있는 경우 - 좌 취소 (gray) / 우 확인 (blue)
                    buttons.length === 1
                      ? 'btn_blue'
                      : classNames({
                          btn_cancel: index === 0,
                          btn_blue: index === 1,
                        })
                  }  h38`}
                  onClick={() =>
                    setOpened(false, () => button.onClick && button.onClick())
                  }
                >
                  {button.name}
                </button>
              ))}
          </menu>
        </dialog>
      )}
    </>
  )
}
