import axios from 'axios'
import { useCallback, useEffect, useRef, useState } from 'react'
import { SERVER_URL } from './dictionary'
import md5 from 'md5'
import sha512 from 'js-sha512'
import { instance } from 'api/core'

export const encodePass = (pass) => {
  const md5Result = md5(pass).toUpperCase()
  const password = sha512(md5Result)
  return password
}

// useQuery 사용시 항상 사용하는 options
export const useQueryCommon = {
  retry: false,
  refetchOnWindowFocus: false,
  onSuccess: (data) => {
    // console.log(data)
  },
  onError: (err) => {
    // console.log(err)
  },
}

export const useStateCallback = (initialState) => {
  const [state, setState] = useState(initialState)
  const cbRef = useRef(null) // init mutable ref container for callbacks

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb // store current, passed callback in ref
    setState(state)
  }, []) // keep object reference stable, exactly like `useState`

  useEffect(() => {
    // cb.current is `null` on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current(state)
      cbRef.current = null // reset callback after execution
    }
  }, [state])

  return [state, setStateCallback]
}

export const getFormattedPhoneNum = (phoneNum) => {
  const endNum = phoneNum.length === 11 ? 7 : 6
  return `${phoneNum.slice(0, 3)}-${phoneNum.slice(3, endNum)}-${phoneNum.slice(
    endNum
  )}`
}
export const getFormattedDay = (date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return year + month + day
}

export const getFormattedTime = (date) => {
  const hour = String(date.getHours()).padStart(2, '0')
  const minute = String(date.getMinutes()).padStart(2, '0')
  return `${hour}시 ${minute}분 `
}

export const getSendEndDateData = (date, setEndDate) => {
  const nowDay = date
  nowDay.setHours(0)
  nowDay.setMinutes(0)
  nowDay.setSeconds(0)
  const afterDay = nowDay
  afterDay.setDate(nowDay.getDate() + 1)
  const year = afterDay.getFullYear()
  const month = String(afterDay.getMonth() + 1).padStart(2, '0')
  const day = String(afterDay.getDate()).padStart(2, '0')

  const viewDay = afterDay
  viewDay.setDate(afterDay.getDate() - 1)
  setEndDate(viewDay)
  return year + month + day
}

export const getFormattedDday = (date) => {
  const now = new Date()
  const start = new Date(date)

  const timeDiff = now.getTime() - start.getTime()
  const dday = Math.floor(timeDiff / (1000 * 60 * 60 * 24))

  return dday === 0 ? '당일 ' : `${dday}일차 `
}

export const sliceDate = (date) => {
  const year = date.slice(0, 4)
  const month = date.slice(4, 6)
  const day = date.slice(6, 8)
  return `${year}/${month}/${day}`
}

export const getFormattedDate = (date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const hour = String(date.getHours()).padStart(2, '0')
  const minute = String(date.getMinutes()).padStart(2, '0')
  return `${year}.${month}.${day} ${hour}:${minute}`
}

export const getFormattedFullDate = (fullDate) => {
  const splitFullDate = fullDate.split('T')
  const date = splitFullDate[0].split('-')
  const time = splitFullDate[1].split(':')
  return `${date[0]}년 ${date[1]}월 ${date[2]}일 ${time[0]}시 ${time[1]}분`
}

export const getFormattedDayDot = (date) => {
  const fullDate = date.split('T')
  const day = fullDate[0].replace(/-/gi, '.')
  return day
}

// 파일 변경
// 한 개
export const putFile = (e, setState) => {
  if (e.target.files[0]) {
    setState(e.target.files[0])
  }
}

// 여러 개
export const putMultipleFiles = (e, setState, num) => {
  // 파일 최대 몇 개 까지 저장할 건지
  if (e.target.files) {
    setState(Array.prototype.slice.call(e.target.files, 0, num))
    // setState(e.target.files)
  }
}

// file format으로 변경
export const convertFileFormat = async (form, key, value) => {
  // url 형식인 경우
  if (typeof value === 'string') {
    const url = `${value}?token=${localStorage.getItem('access_token')}`
    const response = await fetch(url)
    const data = await response.blob()
    const ext = value.split('.').pop()
    const filename = value.split('/').pop()
    const metadata = { type: `image/${ext}` }
    form.append(key, new File([data], filename, metadata))
  }
  // file 형식인 경우
  else {
    form.append(key, value)
  }
}

export const linkOnClick = (source, fileName) => {
  const link = document.createElement('a')
  link.href = source
  if (fileName) {
    link.setAttribute('download', fileName)
  } else {
    link.setAttribute('download', source.split('/').pop())
  }
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

// 클릭 시 파일 다운로드
export const download = (url, fileName) => {
  // 로컬에서 직접 추가힌 데이터
  if (url.startsWith('blob')) {
    linkOnClick(url, fileName)
  }
  // 서버로 부터 받아온 데이터
  else {
    axios({
      url: url.replace(SERVER_URL, ''),
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      linkOnClick(
        window.URL.createObjectURL(new Blob([response.data])),
        fileName.split('?')[0]
      )
    })
  }
}

// checkbox 다중선택 changing Event
export const multiCheckboxChange = (curr, array, setArray) => {
  const tmp = [...array]
  const index = tmp.indexOf(curr)

  // 존재하지 않으면 추가, 존재하면 지우고
  index < 0 ? tmp.push(curr) : tmp.splice(index, 1)
  setArray(tmp)
}

export const multiCheckboxChangeAll = (curr, array, setArray, checkAll) => {
  const tmp = [...array]
  const index = tmp.indexOf(curr)
  const allIndex = tmp.indexOf(checkAll)
  if (curr === checkAll && index < 0) {
    setArray([checkAll])
  } else {
    if (allIndex >= 0) tmp.splice(allIndex, 1)
    // 존재하지 않으면 추가, 존재하면 지우고
    index < 0 ? tmp.push(curr) : tmp.splice(index, 1)
    setArray(tmp)
  }
}

// 30분 단위로 시간 출력
// startTime: 시작 시간
// loopCnt: 반복문 횟수
export const getTimeOptions = (startTime, loopCnt) => {
  const timeArr = []
  let hour = startTime
  for (let i = 0; i < loopCnt; i++) {
    if (i % 2) {
      timeArr.push(`${String(hour).padStart(2, 0)}30`)
      hour++
    } else {
      timeArr.push(`${String(hour).padStart(2, 0)}00`)
    }
  }

  return timeArr.map((time) => ({
    value: time,
    label: `${time.slice(0, 2)}:${time.slice(2)}`,
  }))
}

// 숫자만 setState
export const onlyNumbers = (value, setState) => {
  if (/^[0-9]+$/.test(value) || value === '') setState(value)
}

// form 태그 내에서 array list 추가
export const formAddItem = (e, ref, arr, setArr, checked, setChecked) => {
  e.preventDefault()
  if (ref.current.value.trim()) {
    const value = ref.current.value.replace(/(\s*)/g, '')
    const tmpArr = [...arr]
    tmpArr.push(value)
    setArr(tmpArr)
    const tmpChecked = [...checked]
    tmpChecked.push(value)
    setChecked(tmpChecked)
    ref.current.value = ''
  }
}

// 두 배열 비교 -> 포함하지 않으면 추가
export const addNotExistItem = (sourceArr, TargetArr, setTargetArr) => {
  const tmpArr = [...TargetArr]
  sourceArr.forEach((item) => {
    if (!TargetArr.includes(item)) {
      tmpArr.push(item)
    }
  })
  setTargetArr(tmpArr)
}

// 숫자 3자리 마다 comma
export const NumberComma = (num) =>
  new Intl.NumberFormat('ko-KR').format(Number(num))

// 이메일 유효성 검사
export const validateEmail = (email) =>
  /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(
    email
  )

// 값에 따른 상태 반환
export const typeValueReturn = (value, list) => {
  for (let type of list) {
    if (type.value === value) return type.label
  }
  return '-'
}

export const arrayToString = (arr) => {
  return arr?.toString()?.replace(/,/gi, ', ') || '-'
}

export const formatAgeGender = (date, gender) => {
  const genderReturn = gender === '1' || gender === '3' ? '남성' : '여성'
  const yearStart = gender === '1' || gender === '2' ? '19' : '20'
  const year = yearStart + date.substring(0, 2)
  const nowYear = new Date().getFullYear()
  const age = nowYear - Number(year) + 1
  return ` (${age}세, ${genderReturn})`
}

// 엑셀 파일 다운로드
export const CSVdownload = async (url, params, name) => {
  try {
    if (name) params.query = name
    const res = await instance({
      method: 'get',
      url,
      params,
      responseType: 'blob',
    })
    linkOnClick(window.URL.createObjectURL(res.data))
  } catch (error) {
    console.log(error)
  }
}
