import { Link } from 'react-router-dom'
import { Header } from './Header'

export const Template = ({
  title,
  link,
  children,
  clickState,
  clickEvent,
  setLink,
}) => {
  return (
    <>
      <Header
        clickState={clickState}
        clickEvent={clickEvent}
        setLink={setLink}
      />
      <main id="main">
        <div>
          <h2>
            {title}
            {link && (
              <Link
                to={clickState || link}
                onClick={() => clickState && clickEvent()}
                className="btn_bor_gray go-back right"
              >
                목록으로 돌아가기
              </Link>
            )}
            {/* {clickState && (
              <button
                onClick={() => clickState && clickState()}
                className="btn_bor_gray go-back right"
              >
                목록으로 돌아가기
              </button>
            )} */}
          </h2>
          {children}
        </div>
      </main>
    </>
  )
}
