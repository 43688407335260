import {
  Dialog,
  Loader,
  SelectBox,
  TextArea,
  TextButton,
  TextInput,
  Viewer,
} from 'components'
import { PreviewButton } from 'components/common'
import { Template } from 'layouts/Template'
import { convertFileFormat, useStateCallback } from 'utils/common'
import { INQUIRY_CATEGORIES, SERVER_URL } from 'utils/dictionary'
import { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import styles from '../inquiry.module.scss'
import { recordApi } from 'api'

export const InquiryWrite = () => {
  const { _id } = useParams()
  const { state } = useLocation()
  const [isLoaderActive, setLoaderActive] = useState(false)

  const [category, setCategory] = useState('')
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  // const [isReplyChecked, setReplyChecked] = useState(true)
  const [replyEmail, setReplyEmail] = useState(localStorage.getItem('email'))

  // 뷰어 팝업 관련
  const [isViewerOpened, setViewerOpened] = useStateCallback(false)
  const [startIndex, setStartIndex] = useState(null)

  // 이미지 뷰어 관련
  const imgInputRef0 = useRef()
  const imgInputRef1 = useRef()
  const imgInputRef2 = useRef()
  const imgInputRef3 = useRef()
  const imgInputRef4 = useRef()
  const [img0, setImg0] = useState()
  const [img1, setImg1] = useState()
  const [img2, setImg2] = useState()
  const [img3, setImg3] = useState()
  const [img4, setImg4] = useState()

  const viewerData = [
    {
      state: img0,
      setState: setImg0,
      ref: imgInputRef0,
    },
    {
      state: img1,
      setState: setImg1,
      ref: imgInputRef1,
    },
    {
      state: img2,
      setState: setImg2,
      ref: imgInputRef2,
    },
    {
      state: img3,
      setState: setImg3,
      ref: imgInputRef3,
    },
    {
      state: img4,
      setState: setImg4,
      ref: imgInputRef4,
    },
  ]

  const sendInquiry = async () => {
    setLoaderActive(true)
    try {
      const form = new FormData()
      form.append('group', 'medi')
      form.append(
        'category',
        category.value ? category.value : '서비스 이용문의'
      )
      form.append('value', '서비스 이용문의')
      form.append('title', title)
      form.append('content', content)
      form.append('reply_email', replyEmail)
      img0 && (await convertFileFormat(form, 'images', img0))
      img1 && (await convertFileFormat(form, 'images', img1))
      img2 && (await convertFileFormat(form, 'images', img2))
      img3 && (await convertFileFormat(form, 'images', img3))
      img4 && (await convertFileFormat(form, 'images', img4))

      if (_id) {
        form.append('_id', _id)
        await recordApi.update(form)
      } else await recordApi.create(form)
      setDialogStatus({ type: 'sendDone' })
    } catch (err) {
      setDialogStatus({ type: 'emailErr' })
      setLoaderActive(false)
    }
  }

  useEffect(() => {
    if (state) {
      setReplyEmail(state.reply_email)
      setCategory({ value: state.category, label: state.category })
      setTitle(state.title)
      setContent(state.content)
      state.images &&
        state.images.forEach((img, index) => {
          viewerData[index].setState(img)
        })
    }
  }, [state])

  // 임시 주석
  // useEffect(() => {
  //   // 처리 결과 회신 체크 풀었을 때 replyEmail 초기화
  //   !isReplyChecked && setReplyEmail('')
  // }, [isReplyChecked])

  const [dialogStatus, setDialogStatus] = useState('')
  const [isCheckDelImgPopupOpened, setCheckDelImgPopupOpened] =
    useStateCallback(false) // 이미지 첨부 삭제 팝업
  const [isEmailErrPopupOpened, setEmailErrPopupOpened] =
    useStateCallback(false) // 이메일 미입력
  const [isInquiryErrPopupOpened, setInquiryErrPopupOpened] =
    useStateCallback(false) // 공란 존재
  const [isCheckSendPopupOpened, setCheckSendPopupOpened] =
    useStateCallback(false) // 문의 전송 확인
  const [isSendDonePopupOpened, setSendDonePopupOpened] =
    useStateCallback(false) // 문의 전송 완료

  const dialogUI = {
    checkDeleteImg: {
      setOpened: setCheckDelImgPopupOpened,
      jsx: (
        <Dialog
          className="fs18 fw500 h234 one-sentence"
          isOpened={isCheckDelImgPopupOpened}
          setOpened={setCheckDelImgPopupOpened}
          buttons={[
            {
              name: '취소',
              onClick: () => {
                setDialogStatus({ type: '' })
              },
            },
            {
              name: '확인',
              onClick: () => {
                dialogStatus.item.setState(null)
                setDialogStatus({ type: '' })
              },
            },
          ]}
        >
          삭제하시겠습니까?
        </Dialog>
      ),
    },
    emailErr: {
      setOpened: setEmailErrPopupOpened,
      jsx: (
        <Dialog
          className="fs18 fw500 h234 one-sentence"
          isOpened={isEmailErrPopupOpened}
          setOpened={setEmailErrPopupOpened}
          buttons={[
            {
              name: '확인',
              onClick: () => {
                {
                  setDialogStatus({ type: '' })
                }
              },
            },
          ]}
        >
          처리 결과 이메일을 올바르게 입력해 주세요
        </Dialog>
      ),
    },
    inquiryErr: {
      setOpened: setInquiryErrPopupOpened,
      jsx: (
        <Dialog
          className="fs18 fw500 h234 one-sentence"
          isOpened={isInquiryErrPopupOpened}
          setOpened={setInquiryErrPopupOpened}
          buttons={[
            {
              name: '확인',
              onClick: () => {
                setDialogStatus({ type: '' })
              },
            },
          ]}
        >
          공란이 없는지 다시 한번 확인해 주세요
        </Dialog>
      ),
    },
    checkSend: {
      setOpened: setCheckSendPopupOpened,
      jsx: (
        <Dialog
          className="fs18 fw500 h234 one-sentence"
          isOpened={isCheckSendPopupOpened}
          setOpened={setCheckSendPopupOpened}
          buttons={[
            {
              name: '취소',
              onClick: () => {
                setDialogStatus({ type: '' })
              },
            },
            {
              name: '확인',
              onClick: () => {
                setDialogStatus({ type: '' })
                sendInquiry()
              },
            },
          ]}
        >
          문의를 전송하시겠습니까?
        </Dialog>
      ),
    },
    sendDone: {
      setOpened: setSendDonePopupOpened,
      jsx: (
        <Dialog
          className="fs18 fw500 h234 one-sentence"
          isOpened={isSendDonePopupOpened}
          setOpened={setSendDonePopupOpened}
          buttons={[
            {
              name: '확인',
              onClick: () => {
                window.location.replace('/inquiry')
              },
            },
          ]}
        >
          문의가 성공적으로 접수되었습니다
        </Dialog>
      ),
    },
  }

  useEffect(() => {
    dialogStatus.type && dialogUI[dialogStatus.type].setOpened(true)
  }, [dialogStatus])

  return (
    <>
      {isLoaderActive && <Loader />}
      <Template>
        <h2>고객센터</h2>
        <Link
          to="/inquiry"
          className="btn_bor_gray go-back fs16 fw500 right mt_16"
        >
          목록으로 돌아가기
        </Link>
        <article className={styles.col2}>
          <h3>문의하기</h3>
          <div>
            <div className="mb10">
              <label htmlFor="inquiry-select">분류</label>
              <SelectBox
                width="160px"
                height="34px"
                state={category}
                setState={setCategory}
                options={INQUIRY_CATEGORIES}
                placeholder="문의 분류"
              />
            </div>
            <div className="mb12">
              <label htmlFor="inquiry-title">문의 제목</label>
              <TextInput
                placeholder="50자 이내로 작성해주세요."
                id="inquiry-title"
                height={32}
                maxLength="50"
                defaultValue={state?.title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="mb16">
              <label htmlFor="inquiry-contents">문의 내용</label>
              <TextArea
                placeholder="1000자 이내로 작성해주세요."
                id="inquiry-contents"
                height={240}
                maxLength="1000"
                defaultValue={state?.content}
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
            <div className="mb14">
              <label>이미지 파일첨부</label>
              <div>
                <ul className={`viewer-preview ${styles.preview}`}>
                  <PreviewButton
                    viewerData={viewerData}
                    setViewerOpened={setViewerOpened}
                    setStartIndex={setStartIndex}
                    setDialogStatus={setDialogStatus}
                  />
                </ul>
                <em>* 이미지 파일 형식, 최대 5개 업로드 가능</em>
              </div>
            </div>
            <div>
              <label>처리 결과 회신</label>
              {/* 임시 주석 */}
              {/* <Checkbox
                label="이메일"
                id="처리 결과 회신 - 이메일"
                isChecked={isReplyChecked}
                onChange={() => setReplyChecked(!isReplyChecked)}
              /> */}
              <span>이메일</span>
              <TextInput
                width={248}
                height={32}
                placeholder="메일 주소를 입력하세요"
                id="inquiry-email"
                className="ml30"
                value={replyEmail}
                onChange={(e) =>
                  // isReplyChecked &&
                  setReplyEmail(e.target.value)
                }
              />
            </div>
          </div>
        </article>
        {_id ? (
          <TextButton
            className="btn_blue w196 h38 right mt24 fs18"
            innerText="수정하기"
            onClick={() => {
              if (!replyEmail.trim().length)
                setDialogStatus({ type: 'emailErr' })
              else if (!(title && content && replyEmail))
                setDialogStatus({ type: 'inquiryErr' })
              else setDialogStatus({ type: 'checkSend' })
            }}
          />
        ) : (
          <TextButton
            className="btn_blue w196 h38 right mt24 fs18"
            innerText="문의하기"
            onClick={() => {
              if (!replyEmail.trim().length)
                setDialogStatus({ type: 'emailErr' })
              else if (!(title && content && replyEmail))
                setDialogStatus({ type: 'inquiryErr' })
              else setDialogStatus({ type: 'checkSend' })
            }}
          />
        )}
      </Template>
      <Viewer
        onClick={() => setStartIndex(null)}
        isOpened={isViewerOpened}
        setOpened={setViewerOpened}
        title="문의 첨부 이미지"
        items={viewerData.map((data) => {
          let thumbnail

          if (data.state) {
            thumbnail =
              typeof data.state === 'string'
                ? `${SERVER_URL}${data.state}?token=${localStorage.getItem(
                    'access_token'
                  )}`
                : window.URL.createObjectURL(data.state)
          }

          return {
            thumbnail,
            original: thumbnail,
          }
        })}
        startIndex={startIndex}
      />
      {dialogStatus.type && dialogUI[dialogStatus.type].jsx}
    </>
  )
}
