import { qnaApi } from 'api'

export const qnaNewCount = async (setNewCnt) => {
  try {
    const { data } = await qnaApi.newCount()
    setNewCnt(data.response.count)
  } catch (err) {
    console.error(err)
  }
}
