import {
  Dialog,
  FilterTable,
  Loader,
  PaginationText,
  SearchContainer,
  TextButton,
  TextInput,
} from 'components'
import { RecordTable } from 'layouts/RecordTable'
import { Template } from 'layouts/Template'
import {
  CSVdownload,
  linkOnClick,
  NumberComma,
  useStateCallback,
} from 'utils/common'
import { SYMPTOM_TAGS } from 'utils/dictionary'
import axios from 'axios'
import { ko } from 'date-fns/esm/locale'
import { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useQuery } from 'react-query'
import styles from './recordHistoryList.module.scss'
import { recordApi } from 'api'

export const RecordHistoryList = () => {
  const [page, setPage] = useState(1) // paginate
  const startDateRef = useRef()
  const endDateRef = useRef()
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [patientName, setPatientName] = useState('')
  const [currName, setCurrName] = useState()
  const [isDateErrPopupOpened, setDateErrPopupOpened] = useStateCallback(false)
  const [symptom, setSymptom] = useState(['symptom_all'])
  const [isSearchType, setSearchType] = useState('')

  const { data: historyInfo, refetch } = useQuery(
    'historyInfoQuery',
    async () => {
      let params = {
        page,
        start_date: startDate ? startDate : new Date(),
        end_date: endDate ? endDate : new Date(),
      }

      if (symptom) {
        let value = ''
        if (symptom.length > 0) {
          for (let list of symptom) {
            if (list.indexOf('_all') > 0) value = 'all,'
            else value += list + ','
          }
        } else {
          value = 'all,'
          setSymptom(['symptom_all'])
        }
        value = value.slice(0, -1)
        params.symptom = value
      }

      if (patientName && patientName.trim().length > 0)
        params = { ...params, name: patientName }

      setSearchType('')
      return (await recordApi.historyList(params)).data
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) =>
        data.curr_name ? setCurrName(data.curr_name) : setCurrName(),
      onError: (error) => console.log(error),
    }
  )

  const handleCSVdownload = async () => {
    try {
      let params = {
        start_date: startDate,
        end_date: endDate,
      }
      if (symptom) {
        let value = ''
        if (symptom.length > 0) {
          for (let list of symptom) {
            if (list.indexOf('_all') > 0) value = 'all,'
            else value += list + ','
          }
        } else {
          value = 'all,'
          setSymptom(['symptom_all'])
        }
        value = value.slice(0, -1)
        params.symptom = value
      }

      if (currName) params = { ...params, name: currName }

      CSVdownload('/treat/medi/history/csv', params)
    } catch (error) {
      console.log(error)
    }
  }

  const filterValues = () => [
    {
      key: '증상명',
      value: SYMPTOM_TAGS,
      arr: symptom,
      setArr: setSymptom,
      checkAll: 'symptom_all',
    },
  ]

  useEffect(() => {
    !startDate && setStartDate(new Date())
    !endDate && setEndDate(new Date())
  }, [])

  useEffect(() => {
    refetch()
  }, [page, symptom])

  const _ = historyInfo

  if (!_) return <Loader />

  return (
    <>
      <Template>
        <h2 className="mb44">지난 진료 내역</h2>
        <PaginationText
          results={_.results.length}
          totalCount={_.total_count}
          page={_.curr_page}
          setPage={setPage}
          nextAble={_.next_able}
          prevAble={_.prev_able}
        />
        <SearchContainer
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          name={patientName}
          setName={setPatientName}
          title="환자명 조회"
          refetch={refetch}
          isSearchDate={true}
          isSearchType={isSearchType}
          setSearchType={setSearchType}
        />
        <FilterTable values={filterValues} />
        <div className={styles.total}>
          <dl>
            <dt>총 환자수</dt>
            <dd>{_.total_count} 명</dd>
            <dt>총 진료비</dt>
            <dd>{NumberComma(_.total_cost)} 원</dd>
          </dl>
          <TextButton
            className="btn_blue w112 h26"
            innerText="상세 내역 저장"
            onClick={handleCSVdownload}
          />
        </div>
        <RecordTable data={_} />
      </Template>
      <Dialog
        className="fs18 fw500 h234 one-sentence"
        isOpened={isDateErrPopupOpened}
        setOpened={setDateErrPopupOpened}
        buttons={[
          { name: '확인', onClick: () => setDateErrPopupOpened(false) },
        ]}
      >
        날짜를 다시 확인해주세요
      </Dialog>
    </>
  )
}
