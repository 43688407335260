import { userApi } from 'api'
import { TextButton, TextInput } from 'components'
import { useRef } from 'react'
import styles from './login.module.scss'

export const FindId = ({ setPageStatus, setDialogStatus, setFinedID }) => {
  const nameRef = useRef()
  const phoneRef = useRef()

  const find = async (e) => {
    e.preventDefault()
    try {
      const res = (
        await userApi.findId({
          name: nameRef.current.value,
          phone: phoneRef.current.value,
        })
      ).data
      setFinedID(res.username)
      setDialogStatus('idInfo')
    } catch (err) {
      console.error(err)
      setDialogStatus('noId')
    }
  }

  return (
    <>
      <form className={styles.find_form} onSubmit={find}>
        <label htmlFor="name">이름</label>
        <TextInput id="name" ref={nameRef} placeholder="이름을 입력해주세요" />
        <label htmlFor="phone">전화번호</label>
        <TextInput
          id="phone"
          ref={phoneRef}
          placeholder="전화번호를 입력해주세요"
        />
        <div className={styles.button_wrapper}>
          <TextButton
            innerText="뒤로"
            onClick={() => setPageStatus('default')}
            className="btn_back w80 h38 fs16 white"
          />
          <TextButton
            onSubmit={true}
            innerText="확인"
            className="btn_blue w154 h38 fs16"
          />
        </div>
      </form>
    </>
  )
}
