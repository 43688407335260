import React, { useEffect, useRef } from 'react'
import { ko } from 'date-fns/esm/locale'
import DatePicker from 'react-datepicker'
import { TextButton } from 'components'
import { SearchInputBox } from './SearchInputBox'

export const SearchContainer = ({
  refetch,
  search,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  options,
  type,
  setType,
  name,
  setName,
  title,
  isSearchType,
  setSearchType,
  isSearchDate,
  setSearchDate,
}) => {
  const startDateRef = useRef()
  const endDateRef = useRef()

  useEffect(() => {
    if (isSearchDate && isSearchType?.length > 0) refetch()
    else if (!isSearchDate && isSearchType?.length > 0) refetch()
    else if (isSearchDate) refetch()
  }, [isSearchType, isSearchDate])

  return (
    <div className="flex gap24 mt14 mb8" style={{ width: '1224px' }}>
      {/* 기간 조회 */}
      <div className="searchbox period">
        <em>답변작성일</em>
        <label className="calendar" htmlFor="start_date" ref={startDateRef}>
          시작일
          <span className="date">
            <DatePicker
              locale={ko}
              id="start_date"
              className="medi-calendar"
              dateFormat="yyyy.MM.dd"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              maxDate={endDate}
              onCalendarOpen={() => startDateRef.current.classList.add('on')}
              onCalendarClose={() =>
                startDateRef.current.classList.remove('on')
              }
            />
          </span>
        </label>
        <label className="calendar" htmlFor="end_date" ref={endDateRef}>
          종료일
          <span className="date">
            <DatePicker
              locale={ko}
              id="end_date"
              className="medi-calendar"
              dateFormat="yyyy.MM.dd"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              minDate={startDate}
              maxDate={endDate}
              onCalendarOpen={() => endDateRef.current.classList.add('on')}
              onCalendarClose={() => endDateRef.current.classList.remove('on')}
            />
          </span>
        </label>
        <TextButton
          className="btn_bor_blue fs16"
          innerText="기간 조회"
          width={160}
          height={32}
          refetch
          onClick={() => (isSearchDate ? refetch() : setSearchDate(true))}
        />
      </div>

      {/* 이름으로 조회 */}
      {setSearchType && (
        <SearchInputBox
          setSearchType={setSearchType && setSearchType}
          query={name}
          setQuery={setName}
          title={title ? title : '검색어'}
          selectBoxProps={
            title
              ? null
              : {
                  state: type,
                  setState: setType,
                  options: options,
                }
          }
        />
      )}
    </div>
  )
}
