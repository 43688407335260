import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

// styles
import 'assets/styles/application.scss'
import { Login } from 'pages/Login'
import { NoticeList } from 'pages/Notice/NoticeList'
import { NoticeDetail } from 'pages/Notice/NoticeDetail'
import { Profile } from 'pages/Profile'
import { QnaFinishList } from 'pages/Qna/QnaFinishList'
import { QnaNewList } from 'pages/Qna/QnaNewList'
import { QnaDetail } from 'pages/Qna/QnaDetail'
import { InquiryList } from 'pages/Inquiry/InquiryList'
import { InquiryDetail } from 'pages/Inquiry/InquiryDetail'
import { InquiryWrite } from 'pages/Inquiry/InquiryWrite'
import { RecordTodayList } from 'pages/Record/RecordTodayList'
import { RecordDetail } from 'pages/Record/RecordDetail'
import { RecordHistoryList } from 'pages/Record/RecordHistoryList'
import { CalculateLogin } from 'pages/Calculate/CalculateLogin'
import { CalculateDetail } from 'pages/Calculate/CalculateDetail'
import { CounselList } from 'pages/Counsel/CounselList'
import { CounselDetail } from 'pages/Counsel/CounselDetail'

export const ScardocMediRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Navigate to="/today" />} />
      <Route path="/login" element={<Login />} />
      <Route path="notice/*">
        <Route index element={<NoticeList />} />
        <Route path=":_id" element={<NoticeDetail />} />
      </Route>
      <Route path="newQnA/*">
        <Route index element={<QnaNewList />} />
        <Route path=":id" element={<QnaDetail />} />
      </Route>
      <Route path="finishQnA/*">
        <Route index element={<QnaFinishList />} />
        <Route path=":id" element={<QnaDetail />} />
      </Route>
      <Route path="newCounsel/*">
        <Route index element={<CounselList />} />
        <Route path=":id" element={<CounselDetail />} />
      </Route>
      <Route path="finishCounsel/*">
        <Route index element={<CounselList />} />
        <Route path=":id" element={<CounselDetail />} />
      </Route>
      <Route path="inquiry/*">
        <Route index element={<InquiryList />} />
        <Route path=":id" element={<InquiryDetail />} />
        <Route path="write" element={<InquiryWrite />} />
      </Route>
      <Route path="/profile" element={<Profile />} />
      <Route path="today/*">
        <Route index element={<RecordTodayList />} />
        <Route path=":_id" element={<RecordDetail />} />
      </Route>
      <Route path="history/*">
        <Route index element={<RecordHistoryList />} />
        <Route path=":_id" element={<RecordDetail />} />
      </Route>
      <Route path="calculate/*">
        <Route index element={<CalculateLogin />} />
        <Route path="detail" element={<CalculateDetail />} />
      </Route>
    </Routes>
  </BrowserRouter>
)
