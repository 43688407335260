import { userApi } from 'api'
import { Dialog, Loader, TextButton, TextInput } from 'components'
import { Template } from 'layouts/Template'
import { Fragment, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import {
  encodePass,
  useQueryCommon,
  useStateCallback,
  validateEmail,
} from 'utils/common'
import styles from './profile.module.scss'

export const Profile = () => {
  const navigate = useNavigate()

  const [isLoaderActive, setLoaderActive] = useState(false)

  const [profile, setProfile] = useState()
  const [username, setUsername] = useState()
  const [phone, setPhone] = useState()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordCheck, setNewPasswordCheck] = useState(' ')

  const { data: user } = useQuery(
    'userInfoQuery',
    async () => (await userApi.profileInfo()).data,
    {
      ...useQueryCommon,
      onSuccess: (data) => {
        setUsername(data.username)
        setPhone(data.phone)
        setEmail(data.email)
        // 변동사항 localStorage와 연동
        localStorage.setItem('email', data.email)
      },
    }
  )

  const { data: hospInfo } = useQuery(
    'hospInfoQuery',
    async () => (await userApi.hospitalInfo()).data,
    {
      ...useQueryCommon,
    }
  )

  const update = async () => {
    setLoaderActive(true)

    // 필수 필드는 email, phone 이며,
    // profile, old_password, new_password 는 변경할 때에만 추가로 필요
    try {
      const form = new FormData()
      form.append('email', email)
      form.append('phone', phone)
      if (password && newPassword === newPasswordCheck) {
        form.append('old_password', encodePass(password))
        form.append('new_password', encodePass(newPassword))
      }
      if (!profile) {
        // 이미지 삭제
        form.append('no_profile', true)
      } else {
        if (typeof profile !== 'string') {
          // 파일 형식이면 이미지 교체, URL 형식이면 그대로 둠
          form.append('profile', profile)
        }
        form.append('no_profile', false)
      }

      await userApi.profileUpdate(form)
      window.location.replace('profile')
    } catch (err) {
      console.error(err)
      if (err.response.data.message === 'password is not matched.')
        setDialogStatus('pwErr')
      setLoaderActive(false)
    }
  }

  const withdrawal = async () => {
    setLoaderActive(true)
    try {
      await userApi.withdrawal()
      navigate('/login')
    } catch (err) {
      console.error(err)
      setLoaderActive(false)
    }
  }

  const leftInfo = [
    {
      id: 'username',
      state: username,
      setState: setUsername,
      name: '아이디',
      readOnly: true,
    },
    {
      id: 'phone',
      state: phone,
      setState: setPhone,
      name: '연락처',
      placeholder: '연락처를',
    },
    {
      id: 'email',
      state: email,
      setState: setEmail,
      name: '이메일 주소',
      placeholder: '이메일을',
    },
  ]

  const rightInfo = [
    {
      id: 'field1',
      state: password,
      setState: setPassword,
      name: '현재 비밀번호',
    },
    {
      id: 'field2',
      state: newPassword,
      setState: setNewPassword,
      name: '신규 비밀번호',
    },
    {
      id: 'field3',
      state: newPasswordCheck,
      setState: setNewPasswordCheck,
      name: '비밀번호 확인',
    },
  ]

  const [dialogStatus, setDialogStatus] = useState('')
  const [isCheckDelImgPopupOpened, setCheckDelImgPopupOpened] =
    useStateCallback(false) // 이미지 첨부 삭제 팝업
  const [isCheckWidthdrawalPopupOpened, setCheckWidthdrawalPopupOpened] =
    useStateCallback(false) // 탈퇴 확인 팝업
  const [isCheckSavePopupOpened, setCheckSavePopupOpened] =
    useStateCallback(false) // 저장 확인 팝업
  const [isPwCheckErrPopupOpened, setPwCheckErrPopupOpened] =
    useStateCallback(false) // 현재 비번 틀림 팝업
  const [isPwErrPopupOpened, setPwErrPopupOpened] = useStateCallback(false) // 비번 모두 입력하지 않음 팝업
  const [isNewPwCheckErrPopupOpened, setNewPwCheckErrPopupOpened] =
    useStateCallback(false) // 신규 비번 확인 오류 팝업
  const [isPhoneEmailEmptyErrPopupOpened, setPhoneEmailEmptyErrPopupOpened] =
    useStateCallback(false) // 연락처 또는 이메일 주소 미입력 오류
  const [isvalidateEmailErrPopupOpened, setvalidateEmailErrPopupOpened] =
    useStateCallback(false) // 이메일 유효하지 않음

  const dialogUI = {
    checkDeleteImg: {
      setOpened: setCheckDelImgPopupOpened,
      jsx: (
        <Dialog
          className="one-sentence"
          isOpened={isCheckDelImgPopupOpened}
          setOpened={setCheckDelImgPopupOpened}
          buttons={[
            {
              name: '취소',
              onClick: () => {
                setDialogStatus('')
              },
            },
            {
              name: '확인',
              onClick: () => {
                setProfile(null)
                setDialogStatus('')
              },
            },
          ]}
        >
          삭제하시겠습니까?
        </Dialog>
      ),
    },
    checkWidthdrawal: {
      setOpened: setCheckWidthdrawalPopupOpened,
      jsx: (
        <Dialog
          className="one-sentence"
          isOpened={isCheckWidthdrawalPopupOpened}
          setOpened={setCheckWidthdrawalPopupOpened}
          buttons={[
            {
              name: '취소',
              onClick: () => {
                setDialogStatus('')
              },
            },
            {
              name: '확인',
              onClick: () => {
                setDialogStatus('')
                withdrawal()
              },
            },
          ]}
        >
          정말 탈퇴하시겠습니까?
        </Dialog>
      ),
    },
    checkSave: {
      setOpened: setCheckSavePopupOpened,
      jsx: (
        <Dialog
          className="one-sentence"
          isOpened={isCheckSavePopupOpened}
          setOpened={setCheckSavePopupOpened}
          buttons={[
            {
              name: '취소',
              onClick: () => {
                setDialogStatus('')
              },
            },
            {
              name: '확인',
              onClick: () => {
                setDialogStatus('')
                update()
              },
            },
          ]}
        >
          저장하시겠습니까?
        </Dialog>
      ),
    },
    pwCheckErr: {
      setOpened: setPwCheckErrPopupOpened,
      jsx: (
        <Dialog
          className="fs18 fw500 h234 one-sentence"
          isOpened={isPwCheckErrPopupOpened}
          setOpened={setPwCheckErrPopupOpened}
          buttons={[{ name: '확인', onClick: () => setDialogStatus('') }]}
        >
          비밀번호를 모두 입력해 주세요
        </Dialog>
      ),
    },
    pwErr: {
      setOpened: setPwErrPopupOpened,
      jsx: (
        <Dialog
          className="fs18 fw500 h234 one-sentence"
          isOpened={isPwErrPopupOpened}
          setOpened={setPwErrPopupOpened}
          buttons={[{ name: '확인', onClick: () => setDialogStatus('') }]}
        >
          현재 비밀번호를 확인해주세요
        </Dialog>
      ),
    },
    newPwCheckErr: {
      setOpened: setNewPwCheckErrPopupOpened,
      jsx: (
        <Dialog
          className="fs18 fw500 h234 one-sentence"
          isOpened={isNewPwCheckErrPopupOpened}
          setOpened={setNewPwCheckErrPopupOpened}
          buttons={[{ name: '확인', onClick: () => setDialogStatus('') }]}
        >
          새로운 비밀번호가 일치하지 않습니다
        </Dialog>
      ),
    },
    phoneEmailEmptyErr: {
      setOpened: setPhoneEmailEmptyErrPopupOpened,
      jsx: (
        <Dialog
          className="fs18 fw500 h234 one-sentence"
          isOpened={isPhoneEmailEmptyErrPopupOpened}
          setOpened={setPhoneEmailEmptyErrPopupOpened}
          buttons={[{ name: '확인', onClick: () => setDialogStatus('') }]}
        >
          연락처와 이메일 주소를 적었는지 다시 확인 해주세요
        </Dialog>
      ),
    },
    validateEmailErr: {
      setOpened: setvalidateEmailErrPopupOpened,
      jsx: (
        <Dialog
          className="fs18 fw500 h234 one-sentence"
          isOpened={isvalidateEmailErrPopupOpened}
          setOpened={setvalidateEmailErrPopupOpened}
          buttons={[{ name: '확인', onClick: () => setDialogStatus('') }]}
        >
          올바른 이메일 주소를 입력하세요
        </Dialog>
      ),
    },
  }

  useEffect(() => {
    dialogStatus && dialogUI[dialogStatus].setOpened(true)
  }, [dialogStatus])

  if (!user || !hospInfo) return <Loader />

  return (
    <>
      {isLoaderActive && <Loader />}

      <Template>
        <section className={styles.profile}>
          <h2 className="fs24 mb20">회원정보수정</h2>
          <div className={styles.col2}>
            <div>
              {leftInfo.map((info, index) => (
                <Fragment key={index}>
                  <label htmlFor={info.id}>{info.name}</label>
                  <TextInput
                    id={info.id}
                    value={info.state || ''}
                    onChange={(e) => info.setState(e.target.value)}
                    placeholder={`${info.placeholder} 입력해주세요`}
                    readOnly={info.readOnly}
                  />
                </Fragment>
              ))}
              <span className="red fs12 block mt8 mb30">
                ※주의: 유효한 연락처 및 이메일주소를 입력하시지 않는 경우
                <br />
                정산 및 각종 알림이 누락 되실 수 있습니다.
              </span>
              {/* <button
                type="button"
                className="blue fw500"
                onClick={() => setDialogStatus('checkWidthdrawal')}
              >
                탈퇴하기
              </button> */}
            </div>
            <div>
              <Fragment>
                <label>병원명</label>
                <TextInput
                  value={hospInfo?.hospital?.hops_name}
                  readOnly={true}
                />
              </Fragment>
              <Fragment>
                <label>병원 연락처</label>
                <TextInput
                  value={hospInfo?.hospital?.hops_tel}
                  readOnly={true}
                />
              </Fragment>
              <Fragment>
                <label>병원 주소</label>
                <TextInput
                  value={hospInfo?.hospital?.hops_addr}
                  readOnly={true}
                />
              </Fragment>
            </div>
            <div>
              <label>비밀번호</label>
              <details>
                <summary>비밀번호 변경</summary>
                <div>
                  {rightInfo.map((info, index) => (
                    <Fragment key={index}>
                      <label htmlFor={info.id}>{info.name}</label>
                      <TextInput
                        isPasswordType={true}
                        id={info.id}
                        onChange={(e) => info.setState(e.target.value)}
                      />
                    </Fragment>
                  ))}
                </div>
              </details>
            </div>
            <div className={styles.buttons}>
              <TextButton
                onClick={() => navigate(-1)}
                className="btn_cancel w226 h38 fs18"
                innerText="취소"
              />
              <TextButton
                onClick={() => {
                  if (
                    // 하나라도 입력이 되어있는데, 모두 입력이 안 되어있을 때
                    (password.trim().length > 0 ||
                      newPassword.trim().length > 0 ||
                      newPasswordCheck.trim().length > 0) &&
                    (password.trim().length === 0 ||
                      newPassword.trim().length === 0 ||
                      newPasswordCheck.trim().length === 0)
                  )
                    setDialogStatus('pwCheckErr')
                  else if (!validateEmail(email))
                    setDialogStatus('validateEmailErr')
                  else if (password && newPassword !== newPasswordCheck)
                    setDialogStatus('newPwCheckErr')
                  else if (!phone.trim().length || !email.trim().length)
                    setDialogStatus('phoneEmailEmptyErr')
                  else setDialogStatus('checkSave')
                }}
                className="btn_blue w226 h38 fs18"
                innerText="확인"
              />
            </div>
          </div>
        </section>
      </Template>
      {dialogStatus && dialogUI[dialogStatus].jsx}
    </>
  )
}
