import { api } from './core'

export const recordApi = {
  todayList: (page) =>
    api.get('/treat/medi/records', {
      page,
    }),
  historyList: (params) => api.get('/treat/medi/history', params),
  detail: (_id) => api.get('/treat/medi', { _id }),
  chargeCreate: (form) => api.formPost('/treat/charge', form),
  chargeUpdate: (form) => api.formPut('/treat/charge', form),
  receiptReject: (params) => api.put('/treat/medi', params),
  receiptUpdate: (params) => api.put('/treat/medi', params),
}
