import { Loader, PaginationText } from 'components'
import { RecordTable } from 'layouts/RecordTable'
import { Template } from 'layouts/Template'
import { useStateCallback } from 'utils/common'
import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { generateDialogUI } from '../common'
import { recordApi } from 'api'

// 당일 진료 내역
export const RecordTodayList = () => {
  const cancelReasonRef = useRef()
  const recommendInputRef = useRef() // 추천과
  const etcInputRef = useRef() // 기타 취소 사유

  // paginate
  const [page, setPage] = useState(1)
  const { data: recordInfo, refetch } = useQuery(
    'recordInfoQuery',
    async () => (await recordApi.todayList(page)).data,
    {
      refetchInterval: 60000, // 1분마다
      refetchIntervalInBackground: true,
      retry: false,
      refetchOnWindowFocus: false,
      onError: (error) => console.log(error),
    }
  )

  useEffect(() => {
    refetch()
  }, [page])

  // 팝업
  const [dialogStatus, setDialogStatus] = useState({ type: null, id: '' })
  // 팝업 종류
  const [isAcceptPopupOpened, setAcceptPopupOpened] = useStateCallback(false)
  const [isCheckAcceptPopupOpened, setCheckAcceptPopupOpened] =
    useStateCallback(false)
  const [isChangePopupOpened, setChangePopupOpened] = useStateCallback(false)
  const [isCheckChangePopupOpened, setCheckChangePopupOpened] =
    useStateCallback(false)
  const [isCancelPopupOpened, setCancelPopupOpened] = useStateCallback(false)
  const [isRecommendErrPopupOpened, setRecommendErrPopupOpened] =
    useStateCallback(false)
  const [isCancelReasonErrPopupOpened, setCancelReasonErrPopupOpened] =
    useStateCallback(false) // 취소 사유 미선택
  const [isEtcErrPopupOpened, setEtcErrPopupOpened] = useStateCallback(false) // 기타 입력하지 않음

  // 접수 관리 API
  const [treatTime, setTreatTime] = useState()
  const [cancelReason, setCancelReason] = useState('')

  const dialogUI = generateDialogUI({
    cancelReasonRef,
    recommendInputRef,
    etcInputRef,
    dialogStatus,
    setDialogStatus,
    isAcceptPopupOpened,
    setAcceptPopupOpened,
    isCheckAcceptPopupOpened,
    setCheckAcceptPopupOpened,
    isChangePopupOpened,
    setChangePopupOpened,
    isCheckChangePopupOpened,
    setCheckChangePopupOpened,
    isCancelPopupOpened,
    setCancelPopupOpened,
    isRecommendErrPopupOpened,
    setRecommendErrPopupOpened,
    isCancelReasonErrPopupOpened,
    setCancelReasonErrPopupOpened,
    isEtcErrPopupOpened,
    setEtcErrPopupOpened,
    treatTime,
    setTreatTime,
    cancelReason,
    setCancelReason,
    refetch,
  })

  useEffect(() => {
    dialogStatus.type && dialogUI[dialogStatus.type].setOpened(true)
  }, [dialogStatus])

  const _ = recordInfo

  if (!_) return <Loader />

  return (
    <>
      <Template>
        <h2 className="mb66">당일 진료 내역</h2>
        <PaginationText
          className="mb48"
          results={_?.results?.length}
          totalCount={_?.total_count}
          page={_?.curr_page}
          setPage={setPage}
          nextAble={_?.next_able}
          prevAble={_?.prev_able}
        />
        <RecordTable
          data={_}
          dialogStatus={dialogStatus}
          setDialogStatus={setDialogStatus}
        />
      </Template>
      {dialogStatus.type && dialogUI[dialogStatus.type].jsx}
    </>
  )
}
