import { useState, useEffect } from 'react'

export const Pagination = ({
  pageLimit,
  viewLimit,
  totalCount,
  page,
  setPage,
  nextAble,
  prevAble,
  className,
}) => {
  const [currentPageArray, setCurrentPageArray] = useState([])
  const [totalPageArray, setTotalPageArray] = useState([])
  const pageCnt = Math.ceil(totalCount / viewLimit) || 0
  useEffect(() => {
    if (page % pageLimit === 1) {
      setCurrentPageArray(totalPageArray[Math.floor(page / pageLimit)])
    } else if (page % pageLimit === 0) {
      setCurrentPageArray(totalPageArray[Math.floor(page / pageLimit) - 1])
    }
  }, [page])
  useEffect(() => {
    const slicedPageArray = sliceArrayByLimit(totalCount, viewLimit, pageLimit)
    setTotalPageArray(slicedPageArray)
    setCurrentPageArray(slicedPageArray[0])
  }, [totalCount])
  return (
    <div
      className={`pagination ${className}`}
      // style={{ padding: type !== 'record' ? '24px 0' : '' }}
    >
      <div
        className={`page-btn ${!prevAble ? 'disable' : ''}`}
        onClick={() => prevAble && setPage(page - 1)}
      >
        <button className={`prev-page ${!prevAble ? 'disable' : ''}`} />
        Previous
      </div>
      <div className="page-num-btn">
        {currentPageArray?.map((i) => (
          <div
            key={i + 1}
            onClick={() => pageCnt >= i + 1 && setPage(i + 1)}
            className={
              pageCnt < i + 1 ? 'inactive' : i + 1 === page ? 'click' : ''
            }
          >
            {i + 1}
          </div>
        ))}
      </div>
      <div
        className={`page-btn ${!nextAble ? 'disable' : ''}`}
        onClick={() => nextAble && setPage(page + 1)}
      >
        Next
        <button className={`next-page ${!nextAble ? 'disable' : ''}`} />
      </div>
    </div>
  )
}
export const sliceArrayByLimit = (totalPageData, viewLimit, pageLimit) => {
  let totalPage = totalPageData <= viewLimit ? pageLimit : totalPageData
  const totalPageArray = Array(totalPage)
    .fill()
    .map((_, i) => i)
  return Array(Math.ceil(totalPage / pageLimit))
    .fill()
    .map(() => totalPageArray.splice(0, pageLimit))
}
