import { counselApi } from 'api'

export const counselNewCount = async (setNewCnt) => {
  try {
    const { data } = await counselApi.newCount()
    setNewCnt(data?.consult_count)
  } catch (err) {
    console.error(err)
  }
}
