export const Loader = () => (
  <div className="ispinner ispinner-large">
    <div className="ispinner-blade"></div>
    <div className="ispinner-blade"></div>
    <div className="ispinner-blade"></div>
    <div className="ispinner-blade"></div>
    <div className="ispinner-blade"></div>
    <div className="ispinner-blade"></div>
    <div className="ispinner-blade"></div>
    <div className="ispinner-blade"></div>
  </div>
)
