import { Loader, Pagination, TextButton, TextInput } from 'components'
import { Template } from 'layouts/Template'
import { getFormattedDay } from 'utils/common'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import styles from '../inquiry.module.scss'
import { inquiryApi } from 'api'

export const InquiryList = () => {
  const navigate = useNavigate()
  const [name, setName] = useState('')

  // paginate
  const [page, setPage] = useState(1)

  const { data: inquiryReq, refetch } = useQuery(
    'inquiryQuery',
    async () => {
      let params = {
        page,
        group: 'medi',
      }
      if (name && name.trim().length > 0) params = { ...params, query: name }
      return (await inquiryApi.list(params)).data
    },
    {
      isRefetching: false,
      retry: false,
      refetchOnWindowFocus: false,
      onError: (error) => console.log(error),
    }
  )

  const data = [
    { key: 'num', name: '글번호' },
    { key: 'title', name: '제목' },
    { key: 'category', name: '분류' },
    { key: 'status', name: '답변 상태' },
    { key: 'createdAt', name: '작성 일시' },
  ]

  const _ = inquiryReq

  if (!_) return <Loader />

  return (
    <Template>
      <h2>고객센터</h2>
      <>
        <Pagination
          results={_.results.length}
          totalCount={_.total_count}
          page={_.curr_page}
          setPage={setPage}
          nextAble={_.next_able}
          prevAble={_.prev_able}
        />
        <div className={styles.searchbox}>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              refetch()
            }}
          >
            <em>제목</em>
            <TextInput
              value={name}
              width={214}
              height={32}
              onChange={(e) => setName(e.target.value)}
            />
            <TextButton
              onSubmit={true}
              className="btn_bor_blue w80 h32"
              innerText="검색"
            />
          </form>
        </div>
        <table className="inquiry">
          <thead>
            <tr>
              {data.map((th, index) => (
                <th key={index}>{th.name}</th>
              ))}
            </tr>
          </thead>
          {_.results.length > 0 ? (
            <tbody className="list">
              {_.results.map((result, resultIndex) => (
                <tr
                  key={resultIndex}
                  onClick={() => navigate(`/inquiry/${result._id}`)}
                >
                  {data.map((td, index) => (
                    <td key={index}>
                      {(() => {
                        switch (td.key) {
                          case 'num':
                            return (
                              _?.total_count - (page - 1) * 10 - resultIndex
                            )
                          case 'status':
                            return result[td.key] ? '답변완료' : '미답변'
                          case 'createdAt':
                            return getFormattedDay(new Date(result[td.key]))
                          default:
                            return result[td.key]
                        }
                      })()}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody className="none">
              <tr>
                <td
                  style={{
                    width: '100%',
                  }}
                >
                  <p style={{ width: '100%', textAlign: 'center' }}>
                    작성된 문의가 없습니다.
                  </p>
                </td>
              </tr>
            </tbody>
          )}
        </table>
        <div className="flex row-reverse mt18">
          <TextButton
            className="btn_blue w196 h38 fs18"
            innerText="작성하기"
            onClick={() => {
              navigate('write')
            }}
          />
        </div>
      </>
    </Template>
  )
}
