import { qnaApi } from 'api'
import { Loader, Pagination, SearchContainer, Table } from 'components'
import { Template } from 'layouts/Template'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import {
  getFormattedDay,
  getFormattedDayDot,
  getFormattedDday,
  getSendEndDateData,
  sliceDate,
  useQueryCommon,
} from 'utils/common'

export const QnaFinishList = () => {
  const [page, setPage] = useState(1)
  const [name, setName] = useState('')
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [isSearchType, setSearchType] = useState('')
  const [isSearchDate, setSearchDate] = useState(false)

  const { data: finishQnAReq, refetch } = useQuery(
    'finishQnAQuery',
    async () => {
      const params = {
        page,
        page_size: 10,
        get_all: false,
      }
      if (isSearchDate) {
        if (startDate) params.start_reply = getFormattedDay(startDate)
        if (endDate) params.end_reply = getSendEndDateData(endDate, setEndDate)
      }
      if ((isSearchType === 'name' || name.length > 0) && name)
        params.query = name
      const { data } = await qnaApi.finishList(params)
      setSearchType('')
      return data.response
    },
    useQueryCommon
  )

  const finishQnAValues = (res) => [
    {
      key: '상담요청일',
      width: 150,
      value: res && <span>{getFormattedDayDot(res?.askedAt)}</span>,
    },
    {
      key: '답변작성일',
      width: 150,
      value: res && <span>{getFormattedDayDot(res?.replyedAt)}</span>,
    },
    {
      key: '수상일 (경과일)',
      width: 250,
      value: res && (
        <>
          <span>{sliceDate(res?.burn_date)?.replace(/\//gi, '.')}</span>
          <span>
            {' '}
            (
            {getFormattedDday(sliceDate(res?.burn_date))
              .replace('차', ' ')
              .trim()}
            )
          </span>
        </>
      ),
    },
    {
      key: '환자 정보 ',
      width: 250,
      value: `${res?.name} (${res?.age}세, ${res?.gender})`,
    },

    {
      key: '화상원인',
      width: 150,
      value: res?.burn_cause2,
    },
    { key: '부위', width: 150, value: res?.burn_area },
    {
      key: '총 질문수',
      width: 150,
      value: res?.complete_count,
    },
  ]

  useEffect(() => {
    !startDate && setStartDate(new Date())
    !endDate && setEndDate(new Date())
  }, [])

  useEffect(() => {
    refetch()
  }, [page])

  const _ = finishQnAReq

  if (!_) return <Loader />

  return (
    <>
      <Template title="완료된 질문">
        <SearchContainer
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          name={name}
          setName={setName}
          title="화상원인조회"
          isSearchDate={isSearchDate}
          setSearchDate={setSearchDate}
          isSearchType={isSearchType}
          setSearchType={setSearchType}
          refetch={refetch}
        />

        <Table
          resData={_?.list}
          values={finishQnAValues}
          emptyText="내역이 없습니다"
          detailPageId="qid"
        />
        <Pagination
          pageLimit={5}
          viewLimit={10}
          totalCount={_?.list.length || 0}
          page={page || 0}
          setPage={setPage}
          nextAble={_?.total_page > page || 0}
          prevAble={page !== 1}
        />
      </Template>
    </>
  )
}
