import { qnaApi } from 'api'
import {
  arrowDown,
  arrowDownDefault,
  arrowUp,
  arrowUpDefault,
  del,
} from 'assets/img'
import { Dialog, Loader, TextArea, TextButton } from 'components'
import { ImgReadView } from 'components/ImgReadView'
import { Template } from 'layouts/Template'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  arrayToString,
  getFormattedFullDate,
  getFormattedDday,
  sliceDate,
  useQueryCommon,
  useStateCallback,
} from 'utils/common'
import styles from './qnaDetail.module.scss'

export const QnaDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const location = useLocation()
  const [isHistory, setHistory] = useState(window.history)

  const [isUpdate, setUpdate] = useState(false)
  const [isData, setData] = useState()
  const [isAnswer, setAnswer] = useState('')
  const [isImgIndex, setImgIndex] = useState(0)
  const [isLink, setLink] = useState('')
  const [isBurnImgList, setBurnImgList] = useState([])

  const [dialogStatus, setDialogStatus] = useState({})
  const [isSuccessPopupOpend, setSuccessPopupOpened] = useStateCallback(false)
  const [isNoSavePopupOpened, setNoSavePopupOpened] = useStateCallback(false)
  const [isImgViewPopupOpened, setImgViewPopupOpened] = useStateCallback(false)

  const { data: _, refetch } = useQuery(
    'newQnaDetailQuery',
    async () => {
      const { data } = await qnaApi.detail(id)
      handleShowContent(data.response)
      let list = [
        data.response.questionnaire,
        ...data.response.questionnaire.progress,
      ]
      setBurnImgList(list)
      return data.response
    },
    useQueryCommon
  )

  const handleCheckSave = (fn) => {
    if (isAnswer.length > 0 && isData?.answer !== isAnswer)
      setDialogStatus({ type: 'noSave', fn: fn })
    else {
      if (fn && fn.name === 'handleShowContent')
        handleShowContent(fn.res, fn.file)
      else if (fn === 'back')
        isUpdate ? navigate('/finishQna') : navigate('/newQna')
      else if (fn === 'link') navigate(isLink)
    }
  }

  const returnDialogFunction = (fn) => {
    setAnswer('')
    if (fn?.name === 'handleShowContent') handleShowContent(fn.res, fn.file)
    else if (fn === 'back')
      isUpdate ? navigate('/finishQna') : navigate('/newQna')
    else if (fn === 'link') navigate(isLink)
  }

  const handleShowContent = (res, file) => {
    setAnswer('')
    let data = {}
    if (file === undefined) {
      data = {
        type: 'main',
        dday: getFormattedDday(res?.questionnaire?.createdAt),
        file: res?.questionnaire?.photo1,
        burn: {
          cause: res?.questionnaire?.burn_cause2,
          date: sliceDate(res?.questionnaire?.burn_date),
          area: res?.questionnaire?.burn_area,
          size: res?.questionnaire?.burn_size,
          blister: res?.questionnaire?.blister,
        },
        createdAt: getFormattedFullDate(res?.questionnaire?.createdAt),
      }

      res?.qnas?.some((q) => {
        if (!q.progress_id) {
          data.question = q?.question
          if (q?.answer) {
            data.answer = q?.answer
            setAnswer(q?.answer)
            data.replyedAt = q?.replyedAt
            data.name = q?.m_name
          } else data.answer = ''
          data.qnaIndex = q?.qna_index
          return true
        } else {
          data.question = '등록된 질문이 없습니다.'
          data.answer = null
        }
      })
    } else {
      res?.questionnaire?.progress?.map((burn) => {
        if (burn.photo1 === file) {
          data = {
            type: 'progress',
            mainDday: getFormattedDday(res?.questionnaire?.createdAt),
            dday: getFormattedDday(burn?.createdAt),
            file: burn?.photo1,
            burn: {
              symptom: arrayToString(burn?.symptom),
              drug: arrayToString(burn?.drug),
              dressing: arrayToString(burn?.dressing),
              memo: burn?.memo || '-',
            },
            createdAt: getFormattedFullDate(burn?.createdAt),
            question: '',
            answer: '',
          }
          res?.qnas?.some((q) => {
            if (q?.progress_id === burn?.progress_id) {
              data.question = q?.question
              data.qnaIndex = q?.qna_index
              if (q?.answer) {
                data.answer = q?.answer
                setAnswer(q?.answer)
                data.replyedAt = q?.replyedAt
                data.name = q?.m_name
              } else data.answer = ''
              return true
            } else {
              data.question = '등록된 질문이 없습니다.'
              data.answer = null
            }
          })
        }
      })
    }

    setData(data)
  }

  const postQnaAnswer = async (answer) => {
    try {
      const req = {
        qna_index: isData?.qnaIndex,
        answer: isAnswer,
      }
      await qnaApi.saveAnswer(req)
      setDialogStatus({ type: 'success', state: answer.length > 0 })
    } catch (err) {
      console.error(err)
    }
  }

  const dialogUI = {
    success: {
      setOpened: setSuccessPopupOpened,
      jsx: (
        <Dialog
          className="one-sentence"
          isOpened={isSuccessPopupOpend}
          setOpened={setSuccessPopupOpened}
          buttons={[
            {
              name: '확인',
              onClick: () =>
                isUpdate ? navigate('/finishQna') : navigate('/newQna'),
            },
          ]}
        >
          {dialogStatus.state
            ? '답변이 수정되었습니다.'
            : '답변이 등록되었습니다.'}
        </Dialog>
      ),
    },
    noSave: {
      setOpened: setNoSavePopupOpened,
      jsx: (
        <Dialog
          className="h234 two-sentence"
          isOpened={isNoSavePopupOpened}
          setOpened={setNoSavePopupOpened}
          buttons={[
            {
              name: '돌아가기',
              onClick: () => {
                setLink('')
                setDialogStatus({})
              },
            },
            {
              name: '이동하기',
              onClick: () => returnDialogFunction(dialogStatus.fn),
            },
          ]}
        >
          답변 작성 중 화면 이탈 시<br />
          작성 중인 답변 내용은 저장되지 않습니다.
        </Dialog>
      ),
    },
    imgView: {
      setOpened: setImgViewPopupOpened,
      jsx: (
        <Dialog
          className="fs18 fw500 h781 w870"
          isOpened={isImgViewPopupOpened}
          setOpened={setImgViewPopupOpened}
        >
          <div className={styles.preview_img_del}>
            <img
              src={del}
              onClick={() => setImgViewPopupOpened(false)}
              alt=""
            />
          </div>
          <div className={styles.preview_img}>
            {' '}
            <ImgReadView file={isData?.file} />
          </div>
        </Dialog>
      ),
    },
  }

  const preventClose = (e: BeforeUnloadEvent) => {
    e.preventDefault()
    e.returnValue = '' //Chrome에서 동작하도록; deprecated
  }

  const preventGoBack = () => {
    isHistory.pushState(null, '', location.href)
    handleCheckSave('back')
  }

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    setUpdate(location.pathname.startsWith('/finish'))
  }, [location])

  useEffect(() => {
    dialogStatus.type && dialogUI[dialogStatus.type].setOpened(true)
  }, [dialogStatus])

  useEffect(() => {
    ;(() => {
      window.addEventListener('beforeunload', preventClose)
    })()

    return () => {
      window.removeEventListener('beforeunload', preventClose)
    }
  }, [])
  useEffect(() => {
    ;(() => {
      isHistory.pushState(null, '', location.href)
      window.addEventListener('popstate', preventGoBack)
    })()

    return () => {
      window.removeEventListener('popstate', preventGoBack)
    }
  }, [isAnswer, isData])

  useEffect(() => {
    isLink.length > 0 && handleCheckSave('link')
  }, [isLink])

  if (!_) return <Loader />

  return (
    <Template
      title={isUpdate ? '답변 수정하기' : '답변 등록하기'}
      link={isUpdate ? '/finishQna' : '/newQna'}
      clickState={isAnswer.length > 0 && isData?.answer !== isAnswer}
      clickEvent={() => handleCheckSave('back')}
      setLink={setLink}
    >
      <section className={styles.info}>
        <div>
          <span>환자 정보</span>
          <dl>
            {_?.user?.name} ({_?.user?.age}세,{' '}
            {_?.user?.gender === '1' ? '남' : '여'})
          </dl>
        </div>
        <div>
          <span>상처 정보</span>
          <dl>
            <dt>원인</dt>
            <dd>{_?.questionnaire?.burn_cause2}</dd>
            <dt>수상일</dt>
            <dd>
              {_?.questionnaire?.burn_date &&
                sliceDate(_?.questionnaire?.burn_date)}
            </dd>
            <dt>부위</dt>
            <dd>{_?.questionnaire?.burn_area || '-'}</dd>
            <dt>총 질문 수</dt>
            <dd>{_?.total_qna_count}</dd>
          </dl>
        </div>
      </section>
      <div className={styles.talk}>
        <section className={styles.img_list}>
          <span>상처 이미지</span>
          <div>
            {isImgIndex === 0 ? (
              <img src={arrowUpDefault} alt="" style={{ cursor: 'default' }} />
            ) : (
              <img
                src={arrowUp}
                alt=""
                onClick={() => setImgIndex(isImgIndex - 1)}
              />
            )}
            <div className={styles.img_list__view}>
              {isBurnImgList?.length > 0 &&
                isBurnImgList?.map((burns, i) => {
                  return (
                    i >= isImgIndex &&
                    i < isImgIndex + 4 && (
                      <div className={styles.img_view} key={burns?.photo1}>
                        <ImgReadView
                          file={burns?.photo1}
                          onClick={() =>
                            burns?.photo1 &&
                            handleCheckSave({
                              name: 'handleShowContent',
                              res: _,
                              file: burns?.progress ? undefined : burns?.photo1,
                            })
                          }
                        />
                        <em className={styles.img_view__dday}>
                          {getFormattedDday(
                            burns?.progress
                              ? _?.questionnaire?.createdAt
                              : burns?.createdAt
                          )?.replace('차', '')}
                        </em>
                      </div>
                    )
                  )
                })}
            </div>

            {isImgIndex === _?.questionnaire?.progress.length - 1 ||
            _?.questionnaire?.progress.length <= 4 ? (
              <img
                src={arrowDownDefault}
                alt=""
                style={{ cursor: 'default' }}
              />
            ) : (
              <img
                src={arrowDown}
                alt=""
                onClick={() => setImgIndex(isImgIndex + 1)}
              />
            )}
          </div>
        </section>
        <div>
          {isData?.type === 'progress' && (
            <section className={styles.writing_menu}>
              <div>
                <span>작성 중인 상담</span>
                <b className="ml24">{isData?.mainDday} 경과 기록</b>
              </div>
              <button
                className={styles.writing_menu__return}
                onClick={() =>
                  handleCheckSave({
                    name: 'handleShowContent',
                    res: _,
                  })
                }
              >
                작성 중인 상담으로 돌아가기
              </button>
            </section>
          )}
          <section className={styles.contents}>
            <span>상담 내용</span>
            <div>
              <b>
                {isData?.dday}
                경과 기록
              </b>
              <div className={styles.contents__box}>
                <div className="flex gap48">
                  <dl>
                    <dt>사진</dt>
                    <dd>
                      {isData?.file && (
                        <div
                          className={styles.img_view}
                          onClick={() => setDialogStatus({ type: 'imgView' })}
                        >
                          <ImgReadView file={isData?.file} />
                          <em className={styles.img_view__dday}>
                            {isData?.dday.replace('차', '')}
                          </em>
                        </div>
                      )}
                    </dd>
                  </dl>
                  <dl className="flex column gap16">
                    {isData?.type === 'main' ? (
                      <>
                        {' '}
                        <dl>
                          <dt>종류</dt>
                          <dd>{isData?.burn?.cause || '-'}</dd>
                        </dl>
                        <dl>
                          <dt>수상일</dt>
                          <dd>{isData?.burn?.date || '-'}</dd>
                        </dl>
                        <dl>
                          <dt>부위</dt>
                          <dd>{isData?.burn?.area || '-'}</dd>
                        </dl>
                        <dl>
                          <dt>크기</dt>
                          <dd>{isData?.burn?.size || '-'}</dd>
                        </dl>
                        <dl>
                          <dt>물집</dt>
                          <dd>{isData?.burn?.blister || '-'}</dd>
                        </dl>
                      </>
                    ) : (
                      <>
                        <dl>
                          <dt>증상</dt>
                          <dd>{isData?.burn?.symptom || '-'}</dd>
                        </dl>
                        <dl>
                          <dt>복용약</dt>
                          <dd>{isData?.burn?.drug || '-'}</dd>
                        </dl>
                        <dl>
                          <dt>드레싱</dt>
                          <dd>{isData?.burn?.dressing || '-'}</dd>
                        </dl>
                        <dl>
                          <dt>메모</dt>
                          <dd style={{ width: '578px' }}>
                            {isData?.burn?.memo || '-'}
                          </dd>
                        </dl>
                      </>
                    )}
                  </dl>
                </div>
                <dl>
                  <dt>질문</dt>
                  <dd>
                    <div className={styles.contents__sub}>
                      <span>상담작성일시</span>
                      <span>{isData?.createdAt}</span>
                    </div>
                    <div className={styles.contents__question}>
                      {isData?.question}
                    </div>
                  </dd>
                </dl>
                <dl>
                  <dt>답변</dt>
                  {isData?.answer === undefined ? (
                    <dd style={{ height: '121px' }}>
                      <div className={styles.contents__sub}>
                        <span>작성된 답변이 없습니다.</span>
                      </div>{' '}
                    </dd>
                  ) : (
                    <dd
                      style={
                        isData?.type === 'main'
                          ? { height: '193px' }
                          : { height: '120px' }
                      }
                    >
                      {isData?.answer?.length > 0 && (
                        <>
                          <div className={styles.contents__sub}>
                            <span>상담작성일시</span>
                            <span>
                              {getFormattedFullDate(isData?.replyedAt)}
                            </span>
                          </div>
                          <div className={styles.contents__sub}>
                            <span>작성자</span>
                            <span>{isData?.name} 선생님</span>
                          </div>
                        </>
                      )}
                      <TextArea
                        width={848}
                        height={100}
                        placeholder={
                          isData?.answer === null
                            ? '작성된 답변이 없습니다.'
                            : '내용을 입력해주세요.'
                        }
                        readOnly={
                          isData?.answer === null ||
                          isData?.answer === undefined
                        }
                        value={isAnswer}
                        onChange={(e) => setAnswer(e.target.value)}
                      />
                    </dd>
                  )}
                </dl>
              </div>
            </div>
          </section>
        </div>
      </div>
      {isData?.answer !== undefined && isData?.answer !== null && (
        <div className="flex end mt24">
          {isAnswer.length > 0 && isAnswer !== isData?.answer ? (
            <TextButton
              className="btn_blue fs16"
              innerText={isData?.answer?.length > 0 ? '수정' : '등록'}
              width={184}
              height={40}
              onClick={() => postQnaAnswer(isData?.answer)}
            />
          ) : (
            <TextButton
              className="btn_cancel hover_none fs16"
              innerText={isData?.answer?.length > 0 ? '수정' : '등록'}
              width={184}
              height={40}
            />
          )}
        </div>
      )}

      {dialogStatus.type && dialogUI[dialogStatus.type].jsx}
    </Template>
  )
}
