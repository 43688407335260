import { userApi } from 'api'
import { TextButton, TextInput } from 'components'
import { useRef } from 'react'
import { encodePass } from 'utils/common'
import styles from './login.module.scss'

export const NewPw = ({ mediId, token, setDialogStatus }) => {
  const newPasswordRef = useRef()
  const newPasswordCheckRef = useRef()

  const newPassword = async (e) => {
    e.preventDefault()

    if (newPasswordRef.current.value !== newPasswordCheckRef.current.value) {
      setDialogStatus('notMatch')
      return
    }

    try {
      await userApi.resetPw({
        medi_id: mediId,
        token,
        password: encodePass(newPasswordRef.current.value),
      })
      setDialogStatus('newPwDone')
    } catch (err) {
      setDialogStatus('notMatch')
      console.error(err)
    }
  }

  return (
    <>
      <em className="fs16 fw500 block mb20">새로운 비밀번호를 입력해주세요</em>
      <form className={styles.find_form} onSubmit={newPassword}>
        <label htmlFor="id">새로운 비밀번호</label>
        <TextInput
          id="id"
          ref={newPasswordRef}
          isPasswordType={true}
          placeholder="새로운 비밀번호를 입력해주세요"
        />
        <label htmlFor="name">비밀번호 재입력</label>
        <TextInput
          id="name"
          ref={newPasswordCheckRef}
          isPasswordType={true}
          placeholder="새로운 비밀번호를 재입력 해주세요"
        />
        <div className={styles.button_wrapper}>
          <TextButton
            onSubmit={true}
            className="btn_blue w154 h38 right"
            innerText="비밀번호 재설정"
          />
        </div>
      </form>
    </>
  )
}
