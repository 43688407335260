import { inquiryApi } from 'api'
import { reply } from 'assets/img'
import { Dialog, Loader, TextArea, TextButton, Viewer } from 'components'
import { Template } from 'layouts/Template'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getFormattedDay, useStateCallback } from 'utils/common'
import styles from '../inquiry.module.scss'

export const InquiryDetail = () => {
  const { _id } = useParams()
  const navigate = useNavigate()

  const [isCheckPopupOpened, setCheckPopupOpened] = useStateCallback(false) // 삭제 요청 확인
  const [isDonePopupOpened, setDonePopupOpened] = useStateCallback(false) // 삭제 완료

  // 고객센터 첨부파일 이미지 뷰어 관련
  const [isInquiryViewerOpened, setInquiryViewerOpened] =
    useStateCallback(false)
  const [startIndexInquiryViewer, setStartIndexInquiryViewer] = useState(null)

  const { data: inquiryDetailReq } = useQuery(
    'inquiryDetailQuery',
    async () => (await inquiryApi.detail(_id)).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (error) => console.log(error),
    }
  )

  const deleteInquiry = async () => {
    try {
      await inquiryApi.delete(_._id)
      setDonePopupOpened(true)
    } catch (err) {
      console.log(err)
    }
  }

  const _ = inquiryDetailReq

  if (!_) return <Loader />

  return (
    <Template>
      <h2>고객센터</h2>
      <Link
        to="/inquiry"
        className="btn_bor_gray go-back fs16 fw500 right mt_16"
      >
        목록으로 돌아가기
      </Link>
      <main className={styles.content_box}>
        <div className={styles.header}>
          <h3>
            {_.title}
            <span>{_.category}</span>
            {_.status ? (
              <span>답변 완료</span>
            ) : (
              <span
                style={{
                  color: '#9b9fa7',
                }}
              >
                미답변
              </span>
            )}
            <span className="fs16">
              {getFormattedDay(new Date(_.createdAt))}
            </span>
          </h3>
        </div>
        <div className={styles.content}>
          <p>{_.content}</p>
          <div>
            <p className="fs16 fw500">첨부 이미지</p>
            {_?.images?.length > 0 ? (
              <ul className="viewer-preview">
                {_.images.map((img, index) => (
                  <li key={index}>
                    <button
                      className="thumnail"
                      type="button"
                      onClick={() => {
                        setInquiryViewerOpened(true)
                        setStartIndexInquiryViewer(index)
                      }}
                    >
                      <img
                        src={`${img}?token=${localStorage.getItem(
                          'access_token'
                        )}`}
                      />
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <div className={styles.no_file}>첨부 파일이 없습니다</div>
            )}
          </div>
        </div>
      </main>
      {_.status ? (
        <div className={styles.answer}>
          <img src={reply} />
          <div>
            <p>
              답변 내용
              <span>
                {_?.answer_date && getFormattedDay(new Date(_?.answer_date))}
              </span>
            </p>
            <TextArea
              backgroundColor="#fff"
              height={144}
              value={_?.answer}
              readOnly
            />
          </div>
        </div>
      ) : (
        <div className="flex gap16 row-reverse mt20">
          <TextButton
            className="btn_delete w130 h32"
            innerText="삭제하기"
            onClick={() => {
              setCheckPopupOpened(true)
            }}
          />
          <TextButton
            className="btn_bor_blue w130 h32"
            innerText="수정하기"
            onClick={() => navigate(`/inquiry/update/${_._id}`, { state: _ })}
          />
        </div>
      )}
      <Dialog
        className="fs18 fw500 h234 one-sentence"
        isOpened={isCheckPopupOpened}
        setOpened={setCheckPopupOpened}
        buttons={[
          {
            name: '취소',
            onClick: () => {},
          },
          {
            name: '확인',
            onClick: () => {
              deleteInquiry()
            },
          },
        ]}
      >
        삭제하시겠습니까?
      </Dialog>
      {isDonePopupOpened && (
        <Dialog
          className="fs18 fw500 h234 one-sentence"
          isOpened={isDonePopupOpened}
          setOpened={setDonePopupOpened}
          buttons={[
            {
              name: '확인',
              onClick: () => {
                navigate('/inquiry')
              },
            },
          ]}
        >
          삭제되었습니다.
        </Dialog>
      )}
      <Viewer
        onClick={() => setStartIndexInquiryViewer(null)}
        isOpened={isInquiryViewerOpened}
        setOpened={setInquiryViewerOpened}
        title="문의 첨부 이미지"
        items={_.images.map((img) => {
          const data = {
            original: `${img}?token=${localStorage.getItem('access_token')}`,
            thumbnail: `${img}?token=${localStorage.getItem('access_token')}`,
          }

          return data
        })}
        startIndex={startIndexInquiryViewer}
      />
    </Template>
  )
}
